import React, { useState } from 'react'
import { Star } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { submitFeedback } from '../features/partner/partnerSlice'
import { confirmReview } from '../features/campaign/campaignSlice'

const CustomerFeedback = ({ googleReviewUrl, clientID }) => {
  const dispatch = useDispatch()
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState('')
  const [step, setStep] = useState('rating')

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating)
  }

  const onFeedbackSubmit = (feedbackData) => {
    dispatch(submitFeedback(feedbackData))
  }

  const handleContinue = () => {
    if (rating > 3) {
      if (googleReviewUrl) {
        window.open(googleReviewUrl, '_blank', 'noopener,noreferrer')
        dispatch(confirmReview(clientID))
      } else {
        console.error('Google Review URL is not defined')
      }
      setStep('thanks')
    } else {
      setStep('feedback')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onFeedbackSubmit({ rating, feedback, clientID })
    setStep('thanks')
    setTimeout(() => {
      dispatch(confirmReview(clientID))
    }, 5000) // Delay the confirmation by 5 seconds
  }

  const renderStep = () => {
    switch (step) {
      case 'rating':
        return (
          <>
            <h2 className='text-xl font-bold mb-4 text-center'>We would love to hear from you, how would you rate your experience with us?</h2>
            <div className='flex justify-center mb-4'>
              {[1, 2, 3, 4, 5].map((star) => (
                <button key={star} onClick={() => handleStarClick(star)} className='focus:outline-none' aria-label={`Rate ${star} stars`}>
                  <Star size={32} className={`${rating >= star ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'} cursor-pointer`} />
                </button>
              ))}
            </div>
            {rating > 0 && (
              <div className='text-center'>
                <button onClick={handleContinue} className='btn btn-primary mb-20'>
                  Submit
                </button>
              </div>
            )}
          </>
        )
      case 'feedback':
        return (
          <form onSubmit={handleSubmit}>
            <h2 className='text-xl font-bold mb-4 text-center'>Thank you for your feedback</h2>
            <div className='mb-4'>
              <label htmlFor='feedback' className='block text-sm font-medium text-gray-700 mb-2'>
                Please let us know what we can do better?
              </label>
              <textarea
                id='feedback'
                rows='3'
                className='w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder='Your feedback is valuable to us...'></textarea>
            </div>
            <div className='text-center'>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </div>
          </form>
        )
      case 'thanks':
        return (
          <div className='text-center'>
            <h2 className='text-xl font-bold mb-2'>Thank You!</h2>
            <p>We appreciate your feedback.</p>
          </div>
        )
      default:
        return null // Add this default case
    }
  }

  return renderStep()
}

export default CustomerFeedback
