import api from '../../api'
const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/customers`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

const joinCampaign = async (campaignID, token, referringCustomerID = null) => {
  try {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    })

    let url = `${API_URL}/campaign/${campaignID}`

    if (referringCustomerID) {
      url += `?referringCustomerID=${referringCustomerID}`
    }

    const request = new Request(url, {
      method: 'PUT',
      headers: headers,
    })

    const response = await fetch(request)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const data = await response.json()

    return data
  } catch (error) {
    console.error(`An error occurred while joining the campaign: ${error}`)
    throw error
  }
}

// login a new user
const getClientCampaigns = async (clientID) => {
  const response = await api.get(API_URL + `/campaigns/${clientID}`)

  return response.data
}

// *get customer
const getCustomer = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/get-customer`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get my campaigns
const getMyCampaigns = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const campaigns = await api.get(`${API_URL}/my-campaigns`, config)

    return campaigns.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamp count for all campaigns
const getStampCount = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/count-stamps`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamp count for individual campaign
const getCampaignStampCount = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/count-stamps/${campaignID}`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get stamp count for individual campaign
const getCampaignStamps = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/get-stamps/${campaignID}`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get campaign by id
const getCampaign = async (campaignID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const campaign = await api.get(`${API_URL}/campaign/${campaignID}`, config)
    console.log(campaign)

    return campaign.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get referral details
const getReferralDetails = async (referralCode, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const referralDetails = await api.get(`${API_URL}/referral/${referralCode}`, config)

    return referralDetails.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const confirmClientGPDR = async (gpdrSelection, clientID, token) => {
  console.log(gpdrSelection, clientID)
  try {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    })

    const url = `${API_URL}/gpdr/${clientID}?gpdr=${gpdrSelection}`

    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
    })

    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error(`An error occurred while setting client gpdr selection ${error}`)
    throw error
  }
}

const fetchImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

// *get social links for all clients
const getClientSocialLinks = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const customer = await api.get(`${API_URL}/get-client-social`, config)

    return customer.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *get my clients
const getMyClients = async (token) => {
  console.log('service')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const clients = await api.get(`${API_URL}/getclients`, config)

    return clients.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}
const fetchServerImage = async (id, token) => {
  console.log(id)
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

const confirmReview = async (clientID, token) => {
  try {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    })

    const url = `${API_URL}/confirm-review/${clientID}`

    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
    })

    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error(`An error occurred while confirming the review  ${error}`)
    throw error
  }
}

const campaignsService = {
  joinCampaign,
  getCampaign,
  getCustomer,
  getClientCampaigns,
  getMyCampaigns,
  getStampCount,
  getCampaignStampCount,
  getCampaignStamps,
  getReferralDetails,
  confirmClientGPDR,
  fetchImage,
  fetchServerImage,
  getClientSocialLinks,
  getMyClients,
  confirmReview,
}

export default campaignsService
