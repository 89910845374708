import React from 'react'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'

const InfoTooltip = ({ dataTip, position }) => {
  return (
    <span className={`tooltip ${position} mb-2 tooltip-primary`} data-tip={dataTip}>
      <AiOutlineInfoCircle className='text-md' />
    </span>
  )
}

export default InfoTooltip
