import { useSelector } from 'react-redux'

//import delete function from message slice
import { deleteCustomerMessage } from '../../features/message/messageSlice'

import { FaTrashAlt } from 'react-icons/fa'

import { useDispatch } from 'react-redux'

import { CgMail } from 'react-icons/cg'

const MessageBody = () => {
  const { singleMessage, IndividualMessageIsLoading } = useSelector((state) => state.message)
  const { user } = useSelector((state) => state.auth)
  const { profileDetails } = useSelector((state) => state.partner)

  const dispatch = useDispatch()

  const replaceTags = (content) => {
    let replacedContent = content
    replacedContent = replacedContent
      .replace(/{name}/g, user.name)
      .replace(/{companyname}/g, singleMessage?.message?.content?.businessName)
      .replace(/{campaignname}/g, singleMessage?.message?.campaignName)
    // Add more tag replacements as needed
    return replacedContent
  }

  const messageContent = singleMessage?.message?.content?.content

  const renderedMessageContent = messageContent ? replaceTags(messageContent) : 'Select a message to view'

  const renderGhostContent = () => (
    <div className='animate-pulse'>
      <div className='h-4 bg-gray-300 rounded w-1/2 mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-3/4 mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-full mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-2/3 mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-1/2 mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-3/4 mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-full mb-2'></div>
      <div className='h-4 bg-gray-300 rounded w-2/3 mb-2'></div>
    </div>
  )

  const handleDeleteMessage = () => {
    dispatch(deleteCustomerMessage(singleMessage.message._id))
  }
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const date = new Date(singleMessage?.message?.scheduled_at)
  const formattedDate = date.toLocaleString('en-US', options)

  return messageContent ? (
    <div className='border h-full rounded-md bg-white'>
      <div className='flex justify-between'>
        <p className='m-4 text-xs md:text-base text-gray-400'>{formattedDate}</p>
        <div className='m-4 flex justify-end'>
          <FaTrashAlt className='text-grey-400 cursor-pointer' onClick={handleDeleteMessage} />
        </div>
      </div>

      <h1 className='mt-8 font-bold text-base md:text-lg'>{singleMessage?.message?.content?.businessName}</h1>
      <div className='text-left px-5 mt-3 text-xs md:text-base' style={{ whiteSpace: 'pre-line' }}>
        {IndividualMessageIsLoading ? (
          renderGhostContent()
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: renderedMessageContent }}
            style={{
              lineHeight: '1.5',
              textIndent: '0px',
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className='flex flex-col items-center justify-center h-full'>
      {/* <MessageIcon /> */}
      <CgMail className='text-5xl' />
      <p>Select an item to read</p>
      <p className='font-bold text-sm'>Nothing is selected</p>
    </div>
  )
}

export default MessageBody
