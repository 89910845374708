// messageSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import messageService from './messageService'

const initialState = {
  isError: false,
  isSuccess: false,
  AllMessagesIsLoading: false,
  markOpenedIsLoading: false,
  IndividualMessageIsLoading: false,
  deleteMessageIsLoading: false,
  message: '',
  messages: [],
  singleMessage: {},
  openedMessages: {},
  filter: 'All',
  imageCache: {},
  imageFetching: {},
  referFriendMessages: {},
}

// *Get all customer messages
export const getCustomerMessages = createAsyncThunk('messages/getCustomerMessages', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messageService.getCustomerMessages(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get Individual message
export const getIndividualMessage = createAsyncThunk('messages/getIndividualMessage', async (messageID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messageService.getIndividualMessage(messageID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update date/opened of message
export const markOpened = createAsyncThunk('messages/markOpened', async (messageID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messageService.markOpened(messageID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const getMoreCustomerMessages = createAsyncThunk('messages/getMoreCustomerMessages', async (lastMessageID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    console.log(lastMessageID)
    return await messageService.getMoreCustomerMessages(token, lastMessageID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Delete a message by its ID
export const deleteCustomerMessage = createAsyncThunk('messages/deleteCustomerMessage', async (messageID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messageService.deleteCustomerMessage(messageID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchMessageImage = createAsyncThunk('campaign/fetchMessageImage', async (id, { rejectWithValue, getState, dispatch }) => {
  const { imageCache, imageFetching } = getState().message
  const token = getState().auth.user.token

  if (!imageCache[id] && !imageFetching[id]) {
    // Dispatch a custom action to indicate that the fetch has started.
    dispatch({ type: 'fetchMessageImage/started', payload: id })

    try {
      return await messageService.fetchImage(id, token)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  } else {
    throw new Error('Image already loaded or being fetched.')
  }
})

// *Get all refer friend messages
export const getReferFriendMessages = createAsyncThunk('customer/getReferFriendMessages', async (clientID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await messageService.getReferFriendMessages(token, clientID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    reset: (state) => {
      state.AllMessagesIsLoading = false
      state.IndividualMessageIsLoading = false
      state.markOpenedIsLoading = false
      state.deleteMessageIsLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    markMessageOpened: (state, action) => {
      state.openedMessages[action.payload] = true
    },
    clearMessageState: (state) => {
      return { ...initialState }
    },
    resetMessagesState: (state) => {
      state.messages = []
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerMessages.pending, (state) => {
      state.AllMessagesIsLoading = true
    })
    builder.addCase(getCustomerMessages.fulfilled, (state, action) => {
      state.AllMessagesIsLoading = false
      state.isSuccess = true
      state.messages = action.payload
    })
    builder.addCase(getCustomerMessages.rejected, (state, action) => {
      state.AllMessagesIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getIndividualMessage.pending, (state) => {
      state.IndividualMessageIsLoading = true
    })
    builder.addCase(getIndividualMessage.fulfilled, (state, action) => {
      state.IndividualMessageIsLoading = false
      state.isSuccess = true
      state.singleMessage = action.payload
    })
    builder.addCase(getIndividualMessage.rejected, (state, action) => {
      state.IndividualMessageIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(markOpened.pending, (state) => {
      state.markOpenedIsLoading = true
    })
    builder.addCase(markOpened.fulfilled, (state, action) => {
      state.markOpenedIsLoading = false
      state.isSuccess = true
    })
    builder.addCase(markOpened.rejected, (state, action) => {
      state.markOpenedIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getMoreCustomerMessages.pending, (state) => {
      state.AllMessagesIsLoading = true
    })
    builder.addCase(getMoreCustomerMessages.fulfilled, (state, action) => {
      console.log(action.payload)
      state.AllMessagesIsLoading = false
      state.isSuccess = true
      state.messages = [...state.messages, ...action.payload]
    })
    builder.addCase(getMoreCustomerMessages.rejected, (state, action) => {
      state.AllMessagesIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(deleteCustomerMessage.pending, (state) => {
      // set a loading flag to indicate that a delete request is in progress
      state.deleteMessageIsLoading = true
    })
    builder.addCase(deleteCustomerMessage.fulfilled, (state, action) => {
      // clear the loading flag and remove the deleted message from the messages array by its ID
      state.deleteMessageIsLoading = false
      state.messages = state.messages.filter((message) => message._id !== action.payload.deletedMessage)
      console.log(action.payload)
      if (state.singleMessage.message._id === action.payload.deletedMessage) {
        state.singleMessage = {}
      }
    })
    builder.addCase(deleteCustomerMessage.rejected, (state, action) => {
      // clear the loading flag and set an error message to display to the user
      state.deleteMessageIsLoading = false
      state.message = action.payload
    })
    builder.addCase(fetchMessageImage.pending, (state, action) => {
      // set a flag indicating that this image is currently being fetched
    })
    builder
      .addCase('fetchMessageImage/started', (state, action) => {
        state.imageFetching[action.payload] = true
      })
      .addCase(fetchMessageImage.fulfilled, (state, action) => {
        // clear the fetching flag and store the loaded image
        delete state.imageFetching[action.meta.arg]
        state.imageCache[action.meta.arg] = action.payload
      })
      .addCase(fetchMessageImage.rejected, (state, action) => {
        // clear the fetching flag
        delete state.imageFetching[action.meta.arg]

        if (action.error.message === 'Image already loaded or being fetched.') {
          // If the error message is about the image being already loaded or fetched, don't set the isError flag.
          // You can decide how you want to handle this case.
        } else {
          // For any other errors, set the error state.
          state.isError = true
          state.message = action.error.message
        }
      })
    builder.addCase(getReferFriendMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getReferFriendMessages.fulfilled, (state, action) => {
      state.isLoading = false
      state.referFriendMessages = action.payload
    })
    builder.addCase(getReferFriendMessages.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset, markMessageOpened, clearMessageState, setFilter, resetMessagesState } = messagesSlice.actions

export default messagesSlice.reducer
