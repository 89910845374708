import { useState, useEffect } from 'react'
import BackButton from '../components/BackButton'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { fetchCustomer, confirmClientGPDR } from '../features/campaign/campaignSlice'

const PrivacyDashboard = () => {
  const { userDetails, isLoading, isSuccess, isError, message } = useSelector((state) => state.campaign)

  const dispatch = useDispatch()

  // Assuming you would have an action to fetch partners
  useEffect(() => {
    dispatch(fetchCustomer())
  }, [dispatch])

  const [toggles, setToggles] = useState({})

  useEffect(() => {
    // Create initial toggle states based on partners data.
    const initialToggles = {}
    userDetails.clients.forEach((client) => {
      initialToggles[client.clientID] = client.acceptedGPDR
    })
    setToggles(initialToggles)
  }, [userDetails])

  const handleToggleChange = (partnerId, gpdrSelection) => {
    // // Update local state first
    // setToggles((prev) => ({
    //   ...prev,
    //   [partnerId]: !prev[partnerId],
    // }))

    // Then dispatch Redux action
    dispatch(confirmClientGPDR({ gpdr: gpdrSelection, idToUse: partnerId }))
  }

  return (
    <>
      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50 mt-8 mb-20 shadow-lg'>
        <h1 className='font-bold text-2xl md:text-left'>Partner Privacy</h1>
        <div className='divider'></div>
        <p className='text-xs md:text-base text-left mb-4 mt-4'>
          This dashboard lets you manage communications from our partners. To receive messages from a partner, simply toggle the switch to "Allow
          communication". You can change your preferences anytime.
        </p>

        {userDetails.clients.map((client) => (
          <div key={client.clientID} className='flex flex-col sm:flex-row items-start sm:items-center  mb-4'>
            <label className='text-xs font-bold w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>
              {client.businessName}
            </label>
            <div className='form-control'>
              <label className='label cursor-pointer'>
                <input
                  type='checkbox'
                  className='toggle mr-2'
                  checked={client.acceptedGPDR}
                  onChange={() => handleToggleChange(client.clientID, !client.acceptedGPDR)}
                />
                <span className='label-text mr-3'>Allow Communication</span>
              </label>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default PrivacyDashboard
