import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMessageImage, getIndividualMessage, markOpened, markMessageOpened } from '../../features/message/messageSlice'

const MessageItem = ({ message, onListItemClick, index, lastMessageRef }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { openedMessages, imageCache } = useSelector((state) => state.message)

  useEffect(() => {
    if (message.contentID.logo || message.logo) {
      dispatch(fetchMessageImage(message.logo))
    }
  }, [message.contentID.logo, message.logo, dispatch])

  const handleClick = () => {
    dispatch(getIndividualMessage(message._id))
    dispatch(markOpened(message._id))
    onListItemClick()
    // dispatch(fetchProfileDetails(message.clientID))
    dispatch(markMessageOpened(message._id))
  }

  const replaceTags = (content) => {
    let replacedContent = content
    replacedContent = replacedContent
      .replace(/{name}/g, user.name)
      .replace(/{companyname}/g, message?.contentID?.businessName)
      .replace(/{campaignname}/g, message?.campaignName)
    // Add more tag replacements as needed
    return replacedContent
  }

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return date.toLocaleDateString('en-US', options)
  }

  const formatTime = (dateString) => {
    const date = new Date(dateString)
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${hours}:${minutes}`
  }

  return (
    <div key={index} ref={lastMessageRef} className='h-16 border-b flex justify-between cursor-pointer hover:bg-slate-100' onClick={handleClick}>
      <div className='flex items-center ml-2 mr-2 rounded-lg'>
        {/* img  */}
        <div className='image-container rounded-sm' style={{ width: '3rem', height: '3rem', overflow: 'hidden' }}>
          <img
            src={
              imageCache[message.contentID.logo] || imageCache[message.logo]
                ? imageCache[message.contentID.logo] || imageCache[message.logo]
                : '/images/mail-icon.svg'
            }
            alt=''
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        {/* content container */}
        <div className='flex flex-col items-start  ml-2'>
          <p className={`text-xs md:text-md truncate ${!message.opened_at && !openedMessages[message._id] ? 'font-bold text-blue-800' : ''}`}>
            {message.contentID.businessName}
          </p>
          <p className='text-xs md:text-md truncate text-gray-400'>
            {replaceTags(stripHtml(message.contentID.subject)).length > 20
              ? replaceTags(stripHtml(message.contentID.subject)).substring(0, 33) + '...'
              : replaceTags(stripHtml(message.contentID.subject))}
          </p>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'>
        <div className=' text-xs mr-2'>{formatDate(message.scheduled_at)}</div>
        <div className='text-xs'>{formatTime(message.scheduled_at)}</div>
      </div>
    </div>
  )
}

export default MessageItem
