// UserDropdown.js
import React from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { Link, useNavigate } from 'react-router-dom'
import { MdAccountCircle } from 'react-icons/md'
import { FaCoins, FaUser } from 'react-icons/fa'

function UserDropdown({ user, onLogout }) {
  const navigate = useNavigate()

  // Calculate the total TEDS balance
  const totalTedsBalance = parseFloat((user.wallet.madTedsBalance + user.wallet.tedsBalance).toFixed(1))

  // You can adjust this size value as needed
  const iconSize = '2em' // Example size

  return (
    <div className='dropdown dropdown-end'>
      <div tabIndex={0} role='button' className='btn btn-ghost rounded-btn'>
        <MdAccountCircle size={iconSize} /> {/* Adjusted size */}
      </div>
      <ul tabIndex={0} className='menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-56 mt-4'>
        {/* Dropdown items */}
        <li>
          {/* Display Wallet Balance */}
          <Link to='/teds/wallet' className='flex items-center gap-2'>
            <FaCoins />
            <span>Wallet: {totalTedsBalance} TEDS</span>
          </Link>
        </li>
        <li>
          <Link to='/settings' className=''>
            <FaUser /> Profile
          </Link>
        </li>
        <li>
          <button
            className='dropdown-item'
            onClick={() => {
              onLogout()
              navigate('/login')
            }}>
            <IconContext.Provider value={{ className: 'text-xl' }}>
              <FaSignOutAlt />
            </IconContext.Provider>
            Logout
          </button>
        </li>
      </ul>
    </div>
  )
}

export default UserDropdown
