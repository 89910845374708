import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

const SocialLinks = ({ clientID, socialLinks }) => {
  let currentLinks = {}
  if (Array.isArray(socialLinks)) {
    currentLinks = socialLinks.find((links) => links.clientID === clientID) || {}
  }

  const hasLinks = Boolean(
    currentLinks.facebookLink ||
      currentLinks.instagramLink ||
      currentLinks.twitterLink ||
      currentLinks.youtubeLink ||
      currentLinks.emailAddress ||
      currentLinks.phone ||
      currentLinks.website ||
      currentLinks.whatsapp ||
      currentLinks.telegram
  )

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`
    }
    return url
  }

  // Function to format WhatsApp number
  const formatWhatsAppNumber = (number) => {
    return number.replace(/^00/, '+').replace(/^(\+49)(0)/, '$1')
  }

  return (
    <div className='flex flex-col space-y-2 mb-3'>
      <div className='flex space-x-4 justify-center mt-8'>
        {currentLinks.facebookLink && (
          <Tippy content='Visit our Facebook page'>
            <a
              href={ensureProtocol(currentLinks.facebookLink)}
              target='_blank'
              rel='noreferrer'
              className='text-blue-600 hover:text-blue-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/fb-icon.svg'
                alt='Facebook Icon'
                className='w-10 h-10'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
        {currentLinks.instagramLink && (
          <Tippy content='Follow us on Instagram'>
            <a
              href={ensureProtocol(currentLinks.instagramLink)}
              target='_blank'
              rel='noreferrer'
              className='text-pink-600 hover:text-pink-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/ig-icon.svg'
                className='w-10 h-10'
                alt='Instagram Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
        {currentLinks.twitterLink && (
          <Tippy content='Follow us on Twitter'>
            <a
              href={ensureProtocol(currentLinks.twitterLink)}
              target='_blank'
              rel='noreferrer'
              className='text-blue-400 hover:text-blue-600 flex flex-col items-center'>
              <img
                src='/images/social-icons/x-icon.svg'
                className='w-10 h-10'
                alt='X Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
        {currentLinks.youtubeLink && (
          <Tippy content='Watch our videos on YouTube'>
            <a
              href={ensureProtocol(currentLinks.youtubeLink)}
              target='_blank'
              rel='noreferrer'
              className='text-red-600 hover:text-red-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/youtube-icon.svg'
                className='w-10 h-10'
                alt='Youtube Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}

        {currentLinks.whatsapp && (
          <Tippy content='Message us on WhatsApp'>
            <a
              href={`https://wa.me/${formatWhatsAppNumber(currentLinks.whatsapp)}`}
              target='_blank'
              rel='noreferrer'
              className='text-green-500 hover:text-green-700 flex flex-col items-center'>
              <img
                src='/images/social-icons/whatsapp-icon.svg'
                className='w-10 h-10'
                alt='WhatsApp Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
        {currentLinks.telegram && (
          <Tippy content='Message us on Telegram'>
            <a
              href={`https://t.me/${currentLinks.telegram.replace(/^@/, '')}`}
              target='_blank'
              rel='noreferrer'
              className='text-blue-500 hover:text-blue-700 flex flex-col items-center'>
              <img
                src='/images/social-icons/telegram-icon.svg'
                className='w-10 h-10'
                alt='Telegram Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
        {currentLinks.emailAddress && (
          <Tippy content='Send us an email'>
            <a href={`mailto:${currentLinks.emailAddress}`} className='text-gray-600 hover:text-gray-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/email-icon.svg'
                className='w-10 h-10'
                alt='Mail Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}

        {currentLinks.website && (
          <Tippy content='Visit our website'>
            <a
              href={ensureProtocol(currentLinks.website)}
              target='_blank'
              rel='noreferrer'
              className='text-purple-600 hover:text-purple-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/link-icon.svg'
                className='w-10 h-10'
                alt='Website Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}

        {currentLinks.phone && (
          <Tippy content='Call us'>
            <a href={`tel:${currentLinks.phone}`} className='text-green-600 hover:text-green-800 flex flex-col items-center'>
              <img
                src='/images/social-icons/phone-icon.svg'
                className='w-10 h-10'
                alt='Phone Icon'
                onError={(e) => {
                  console.error('Image failed to load:', e)
                }}
              />
            </a>
          </Tippy>
        )}
      </div>
    </div>
  )
}

export default SocialLinks
