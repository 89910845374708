import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AiOutlineQrcode } from 'react-icons/ai'
import { BiScan } from 'react-icons/bi'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { VscSettings } from 'react-icons/vsc'
import { GiHamburgerMenu } from 'react-icons/gi'

function BottomNav() {
  const { user } = useSelector((state) => state.auth)

  if (!user.acceptedPrivacyPolicy) {
    return ''
  }

  return (
    <div className='fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t  border-gray-200 dark:bg-gray-700 dark:border-gray-600 custom-zindex-less'>
      <div className='grid h-full max-w-lg grid-cols-3 mx-auto font-medium '>
        <Link to='/' className='inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group'>
          <GiHamburgerMenu className='text-gray-500 text-2xl dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary' />
          <span className='text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary'>Home</span>
        </Link>

        <Link to='/reader' className='inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group'>
          <BiScan className='text-gray-500 text-2xl dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary' />
          <span className='text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary'>Join Campaign</span>
        </Link>
        <Link to='/myqr' className='inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group'>
          <AiOutlineQrcode className='text-gray-500 text-2xl dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary' />
          <span className='text-sm text-gray-500 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-primary'>My QR</span>
        </Link>
      </div>
    </div>
  )
}

export default BottomNav
