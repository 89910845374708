// serversSlice.js
import { createSlice, current, createAsyncThunk } from '@reduxjs/toolkit'

import campaignService from './campaignService'

const initialState = {
  campaigns: [],
  myCampaigns: [],
  referringClient: null,
  customerReferralCode: null,
  referringCustomerID: null,
  campaign: {},
  userDetails: {
    clients: [],
  },
  clientId: null,
  client: {},
  stamps: [],
  clients: [],
  isLoading: false,
  isError: null,
  isSuccess: false,
  joinedCampaignIsSuccess: false,
  message: '',
  rewardImageUrl: null,
  rewardImageIsError: null,
  rewardImageIsStatus: null,
  rewardImageIsSuccess: null,
  rewardImageIsLoading: null,
  rewardImageErrorMessage: null,
  qrCodeUrl: null,
  qrCodeIsError: null,
  qrCodeIsStatus: null,
  qrCodeIsSuccess: null,
  qrCodeIsLoading: null,
  qrCodeErrorMessage: null,
  stampCountIsLoading: null,
  stampCountIsError: null,
  socialLinks: {},
  imageCache: {},
  imageFetching: {},
  gpdrSelection: false,
}

// *Fetch Campaign via ID
export const fetchCampaign = createAsyncThunk('campaign/fetchCampaign', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getCampaign(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Customer
export const fetchCustomer = createAsyncThunk('campaign/fetchCustomer', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getCustomer(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Campaigns via ID
export const fetchClientCampaigns = createAsyncThunk('campaign/fetchClientCampaigns', async (clientID, thunkAPI) => {
  try {
    return await campaignService.getClientCampaigns(clientID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Stamp count for all campaigns
export const fetchStampCount = createAsyncThunk('campaign/fetchStampCount', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getStampCount(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Stamp count for campaign
export const fetchCampaignStampCount = createAsyncThunk('campaign/fetchCampaignStampCount', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getCampaignStampCount(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch my Campaigns
export const fetchMyCampaigns = createAsyncThunk('campaign/fetchMyCampaigns', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getMyCampaigns(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Join Campaign via ID
export const joinCampaign = createAsyncThunk('campaign/joinCampaign', async ({ campaignID, referringCustomerID }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.joinCampaign(campaignID, token, referringCustomerID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// * Fetch Stamps for campaign
export const fetchCampaignStamps = createAsyncThunk('campaign/fetchCampaignStamps', async (campaignID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getCampaignStamps(campaignID, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Fetch Referral Details via Referral Code - clientID + customerID
export const fetchReferralDetails = createAsyncThunk('campaign/fetchReferralDetails', async (referralCode, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getReferralDetails(referralCode, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *GPDR checkbox per client basis
export const confirmClientGPDR = createAsyncThunk('campaign/client/gpdr', async ({ gpdr, idToUse }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.confirmClientGPDR(gpdr, idToUse, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// *Get image
export const fetchRewardImage = createAsyncThunk('campaign/fetchRewardImage', async (id, { rejectWithValue }) => {
  try {
    return await campaignService.fetchImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// *Get image
export const fetchQrCode = createAsyncThunk('campaign/fetchQrCode', async (id, { rejectWithValue }) => {
  try {
    return await campaignService.fetchImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// *fetch Client Social links
export const fetchClientSocialLinks = createAsyncThunk('campaign/fetchClientSocialLinks', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getClientSocialLinks(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Client Social links
export const fetchMyClients = createAsyncThunk('campaign/fetchMyClients', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.getMyClients(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchServerImage = createAsyncThunk('campaign/fetchServerImage', async (id, { rejectWithValue, getState, dispatch }) => {
  const { imageCache, imageFetching } = getState().message
  const token = getState().auth.user.token

  if (!imageCache[id] && !imageFetching[id]) {
    // Dispatch a custom action to indicate that the fetch has started.
    dispatch({ type: 'fetchServerImage/started', payload: id })

    try {
      return await campaignService.fetchServerImage(id, token)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  } else {
    throw new Error('Image already loaded or being fetched.')
  }
})

// *GPDR checkbox per client basis
export const confirmReview = createAsyncThunk('campaign/confirm-review', async (clientID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await campaignService.confirmReview(clientID, token)
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

const campaignsSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.joinedCampaignIsSuccess = false
      state.message = ''
      state.imageIsError = false
      state.imageErrorMessage = ''
      state.imageIsLoading = ''
      state.imageIsSuccess = ''
      state.rewardImageUrl = null
      state.imageCache = {}
      state.imageFetching = {}
    },
    setReferringClient: (state, action) => {
      // new action for setting clientID
      state.referringClient = action.payload
    },
    setCustomerReferralCode: (state, action) => {
      // new action for setting clientID
      state.customerReferralCode = action.payload
    },
    clearCampaignState: (state) => {
      return { ...initialState }
    },
    setClientId: (state, action) => {
      state.clientId = action.payload
    },
    resetCampaigns: (state) => {
      state.campaigns = []
      state.userDetails.campaignsJoined = ''
    },

    decrementNewStampCount: (state, action) => {
      // Assuming action.payload contains the ID of the campaign to update
      const campaignIdToUpdate = action.payload

      // Find the campaign by ID and decrement its newStampCount
      const campaignIndex = state.myCampaigns.findIndex((campaign) => campaign._id === campaignIdToUpdate)

      if (campaignIndex !== -1) {
        const currentCount = state.myCampaigns[campaignIndex].newStampCount || 0 // Fallback to 0 if undefined
        state.myCampaigns[campaignIndex].newStampCount = currentCount > 0 ? currentCount - 1 : 0 // Ensure it doesn't go below 0
      }
    },
    incrementNewStampCount: (state, action) => {
      // Assuming action.payload contains the ID of the campaign to update
      const campaignIdToUpdate = action.payload

      // Find the campaign by ID and increment its newStampCount
      const campaignIndex = state.myCampaigns.findIndex((campaign) => campaign._id === campaignIdToUpdate)

      if (campaignIndex !== -1) {
        const currentCount = state.myCampaigns[campaignIndex].newStampCount || 0 // Fallback to 0 if undefined
        state.myCampaigns[campaignIndex].newStampCount = currentCount + 1
      }
    },
    removeLastStamp: (state) => {
      state.stamps.pop() // Directly remove the last stamp from the stamps array
    },
    decrementReadyToRedeem: (state, action) => {
      // Assuming action.payload contains the ID of the campaign to update
      const campaignIdToUpdate = action.payload

      // Find the campaign by ID and decrement its readyToRedeem
      const campaignIndex = state.myCampaigns.findIndex((campaign) => campaign._id === campaignIdToUpdate)

      if (campaignIndex !== -1) {
        const currentCount = state.myCampaigns[campaignIndex].readyToRedeem || 0 // Fallback to 0 if undefined
        state.myCampaigns[campaignIndex].readyToRedeem = currentCount > 0 ? currentCount - 1 : 0 // Ensure it doesn't go below 0
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaign.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCampaign.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.campaign = action.payload
    })
    builder.addCase(fetchCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCustomer.pending, (state) => {})
    builder.addCase(fetchCustomer.fulfilled, (state, action) => {
      console.log(action.payload)
      state.userDetails = action.payload
      if (action.payload.campaignsJoined) {
        state.myCampaigns = state.myCampaigns.map((campaign) => {
          const updatedCampaign = action.payload.campaignsJoined.find((item) => item.campaignID === campaign._id)

          if (updatedCampaign) {
            campaign.redeemed = updatedCampaign.redeemed
            campaign.readyToRedeem = updatedCampaign.readyToRedeem
            campaign.rewardsRedeemed = updatedCampaign.rewardsRedeemed
          }
          return campaign
        })
      }
    })
    builder.addCase(fetchCustomer.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchClientCampaigns.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchClientCampaigns.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.campaigns = action.payload
    })
    builder.addCase(fetchClientCampaigns.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
    })
    builder.addCase(joinCampaign.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(joinCampaign.fulfilled, (state, action) => {
      state.isLoading = false
      state.joinedCampaignIsSuccess = true
      state.userDetails = action.payload
      // push the action.payload to state.campaigns array
      //   state.campaigns = [...state.campaigns, action.payload]
    })
    builder.addCase(joinCampaign.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchMyCampaigns.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchMyCampaigns.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.myCampaigns = action.payload
    })
    builder.addCase(fetchMyCampaigns.rejected, (state, action) => {
      state.stampCountIsLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchStampCount.pending, (state) => {
      state.stampCountIsLoading = true
    })
    builder.addCase(fetchStampCount.fulfilled, (state, action) => {
      console.log('Action Payload:', action.payload)
      console.log('Before update - myCampaigns:', JSON.parse(JSON.stringify(state.myCampaigns)))

      // state.stampCountIsLoading = false
      // state.stampCountIsSuccess = true

      state.myCampaigns = state.myCampaigns.map((campaign) => {
        const campaignId = campaign._id.toString()
        console.log(`Processing campaign: ${campaignId}`)
        console.log(`Payload data for this campaign:`, action.payload)

        const updatedCampaign = {
          ...campaign,
          newStampCount: action.payload.campaignStamps[campaignId] || 0,
          claimedStampCount: action.payload.claimedCampaignStamps[campaignId] || 0,
          redeemableStampCount: action.payload.redeemableCampaignStamps[campaignId] || 0,
        }
        console.log('go')

        console.log('Updated campaign:', updatedCampaign)
        return updatedCampaign
      })

      console.log('After update - myCampaigns:', JSON.parse(JSON.stringify(state.myCampaigns)))
    })
    builder.addCase(fetchStampCount.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCampaignStampCount.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCampaignStampCount.fulfilled, (state, action) => {
      state.isLoading = false

      console.log('action.payload', action.payload)
      state.isSuccess = true

      state.campaign = {
        ...state.campaign,
        ...action.payload,
      }
    })
    builder.addCase(fetchCampaignStampCount.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchCampaignStamps.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchCampaignStamps.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.stamps = action.payload
    })
    builder.addCase(fetchCampaignStamps.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase('auth/logout', (state) => {
      return { ...initialState }
    })
    builder.addCase(fetchReferralDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchReferralDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.clientId = action.payload.clientID
      state.referringCustomerID = action.payload.referringCustomerID
    })
    builder.addCase(fetchReferralDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(fetchRewardImage.pending, (state) => {
        state.rewardImageIsLoading = true
      })
      .addCase(fetchRewardImage.fulfilled, (state, action) => {
        state.rewardImageIsLoading = false
        state.rewardImageIsSuccess = true
        state.rewardImageUrl = action.payload
      })
      .addCase(fetchRewardImage.rejected, (state, action) => {
        state.rewardImageIsSuccess = false
        state.rewardImageIsError = true
        state.rewardImageErrorMessage = action.payload
      })
    builder
      .addCase(fetchQrCode.pending, (state) => {
        state.qrCodeIsLoading = true
      })
      .addCase(fetchQrCode.fulfilled, (state, action) => {
        state.qrCodeIsLoading = false
        state.qrCodeIsSuccess = true
        state.qrCodeUrl = action.payload
      })
      .addCase(fetchQrCode.rejected, (state, action) => {
        state.qrCodeIsSuccess = false
        state.qrCodeIsError = true
        state.qrCodeErrorMessage = action.payload
      })
    builder
      .addCase(fetchClientSocialLinks.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchClientSocialLinks.fulfilled, (state, action) => {
        console.log(action.payload)
        state.isLoading = false
        state.isSuccess = true
        state.socialLinks = action.payload
      })
      .addCase(fetchClientSocialLinks.rejected, (state, action) => {
        state.isSuccess = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(fetchServerImage.pending, (state, action) => {
      // set a flag indicating that this image is currently being fetched
    })
    builder
      .addCase('fetchServerImage/started', (state, action) => {
        state.imageFetching[action.payload] = true
      })
      .addCase(fetchServerImage.fulfilled, (state, action) => {
        // clear the fetching flag and store the loaded image
        delete state.imageFetching[action.meta.arg]
        state.imageCache[action.meta.arg] = action.payload
      })
      .addCase(fetchServerImage.rejected, (state, action) => {
        // clear the fetching flag
        delete state.imageFetching[action.meta.arg]

        if (action.error.message === 'Image already loaded or being fetched.') {
          // If the error message is about the image being already loaded or fetched, don't set the isError flag.
          // You can decide how you want to handle this case.
        } else {
          // For any other errors, set the error state.
          state.isError = true
          state.message = action.error.message
        }
      })
    builder
      .addCase(confirmClientGPDR.pending, (state) => {})
      .addCase(confirmClientGPDR.fulfilled, (state, action) => {
        state.isSuccess = true
        console.log(action.payload, 'action payload')

        // Extracting the payload
        const { gpdrSelection, clientID } = action.payload

        // Converting gpdrSelection to a boolean value
        const acceptedGPDR = JSON.parse(gpdrSelection)

        // Finding the client and updating the acceptedGPDR field
        if (state.userDetails && state.userDetails.clients) {
          const client = state.userDetails.clients.find((c) => c.clientID === clientID)
          if (client) {
            client.acceptedGPDR = acceptedGPDR
          }
        }

        // Keeping the original payload assignment if needed elsewhere
        state.gpdrSelection = action.payload
      })
      .addCase(confirmClientGPDR.rejected, (state, action) => {
        state.isSuccess = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(fetchMyClients.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchMyClients.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.clients = action.payload
    })
    builder.addCase(fetchMyClients.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder
      .addCase(confirmReview.pending, (state) => {})
      .addCase(confirmReview.fulfilled, (state, action) => {
        state.isSuccess = true
        console.log(action.payload, 'action payload')

        // Extracting the payload
        const { leftReview, clientID } = action.payload

        // Converting gpdrSelection to a boolean value
        const confirmReview = JSON.parse(leftReview)

        // Finding the client and updating the acceptedGPDR field
        if (state.userDetails && state.userDetails.clients) {
          const client = state.userDetails.clients.find((c) => c.clientID === clientID)
          if (client) {
            client.leftReview = confirmReview
          }
        }

        // Keeping the original payload assignment if needed elsewhere
        // state.gpdrSelection = action.payload
      })
      .addCase(confirmReview.rejected, (state, action) => {
        state.isSuccess = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const {
  reset,
  setCustomerReferralCode,
  setReferringClient,
  setClientId,
  clearCampaignState,
  resetCampaigns,
  decrementNewStampCount,
  decrementReadyToRedeem,
  incrementNewStampCount,
  removeLastStamp,
} = campaignsSlice.actions

export default campaignsSlice.reducer
