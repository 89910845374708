export const getRewardsMessage = (stampCount, goal, rewardName) => {
  switch (stampCount) {
    case 0:
      return `You currently have 0 stamps for this loyalty card.`
    case 1:
      return `You have 1 stamp, earn another ${goal - stampCount} to get your ${rewardName}.`
    case 2:
      return `With 2 stamps, you're making progress! Only ${goal - stampCount} more stamps to get your ${rewardName}.`
    case 3:
      return `Nice! You've got 3 stamps already. Only ${goal - stampCount} stamps away from your ${rewardName}.`
    case 4:
      return `4 stamps down! Just ${goal - stampCount} more to get your ${rewardName}.`
    case 5:
      return `You're halfway there with 5 stamps! Only ${goal - stampCount} more stamps to get your ${rewardName}.`
    case 6:
      return `Keep going! Only ${goal - stampCount} more stamps for your ${rewardName}.`
    case 7:
      return `7 stamps! You're almost there. Just ${goal - stampCount} more to get your ${rewardName}.`
    case 8:
      return `8 stamps, great job! You're just ${goal - stampCount} away from your ${rewardName}.`
    case 9:
      return `You're so close! Only ${goal - stampCount} more stamp to get your ${rewardName}.`
    case 10:
      return `Congrats! You've reached 10 stamps! Only ${goal - stampCount} more to go!`
    case 11:
      return `Amazing! You've got 11 stamps. Time to get your ${rewardName}.`
    case 12:
      return `12 stamps! You've gone above and beyond. Enjoy your ${rewardName}.`
    case 13:
      return `Lucky 13 stamps! You're on a roll. Don't forget to claim your ${rewardName}.`
    case 14:
      return `14 stamps, that's fantastic! Your ${rewardName} is waiting for you.`
    case 15:
      return `15 stamps! You've done an excellent job. Grab your ${rewardName} now.`
    default:
      return ``
  }
}
