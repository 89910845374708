import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/messages`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

// *Get all client messages
const getCustomerMessages = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const message = await api.get(`${API_URL}/getMessages`, config)
    return message.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get all client messages
const getIndividualMessage = async (messageID, token) => {
  console.log(messageID)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const message = await api.get(`${API_URL}/${messageID}`, config)
    return message.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Mark message as opened
const markOpened = async (messageID, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await api.put(`${API_URL}/markOpened`, { messageID }, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Get more client messages
const getMoreCustomerMessages = async (token, lastMessageID) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const url = lastMessageID ? `${API_URL}/getMessages/${lastMessageID}` : `${API_URL}/getMessages`
    const message = await api.get(url, config)
    return message.data
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// *Delete a message by its ID
const deleteCustomerMessage = async (messageID, token) => {
  try {
    // use api.delete() to send a delete request to your API endpoint
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.delete(`${API_URL}/deleteCustomerMessage/${messageID}`, config)
    return response.data // return the response data to the caller
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error // throw the error to the caller
  }
}

const fetchImage = async (id, token) => {
  console.log('runs once')
  console.log(id)
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

// *Get all refer friend messages
// *Get all refer friend messages
const getReferFriendMessages = async (token, clientID) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/customer/get-refer-friend-messages`, {
      params: { clientID },
      ...config,
    })
    return response.data
  } catch (error) {
    // Check for error response
    if (error.response) {
      // Check for status code
      if (error.response.status === 404) {
        // Get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const customersService = {
  getCustomerMessages,
  getMoreCustomerMessages,
  getIndividualMessage,
  markOpened,
  deleteCustomerMessage,
  fetchImage,
  getReferFriendMessages,
}

export default customersService
