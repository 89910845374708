import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../features/auth/authSlice'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { setReferringClient } from '../features/campaign/campaignSlice'
import Spinner from '../components/Spinner'

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const { email, password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  const { clientID } = useSelector((state) => state.campaign)

  const [shouldRedirectToReader, setShouldRedirectToReader] = useState(false)
  // const [clientID, setClientID] = useState(null)

  // gets clientID from query params
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const clientID = params.get('clientID')

    //shows popup if clientID is present
    if (clientID) {
      // setClientID(clientID)
      dispatch(setReferringClient(clientID))
      setShouldRedirectToReader(true)
      const myModalCheckbox = document.getElementById('my-modal-6')
      myModalCheckbox.checked = true
    }

    const timeoutId = setTimeout(() => {
      console.log(shouldRedirectToReader)
      if (user && shouldRedirectToReader) {
        console.log('useEffect 1 called')

        const params = new URLSearchParams()
        params.append('clientId', clientID)
        navigate('/reader?' + params.toString())
      } else if (user && user.qr_code) {
        console.log('useEffect 2 called')
        navigate('/')
      }
    }, 50)

    return () => clearTimeout(timeoutId)
  }, [location.search, user, navigate, clientID, shouldRedirectToReader, dispatch])

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    dispatch(reset())
  }, [isError, isSuccess, dispatch, message])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {/* Global Container */}
      <div className='flex items-center justify-center'>
        {/* Card Container */}
        <div className='relative flex flex-col m-3 space-y-10 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 md:m-0 w-full md:w-auto'>
          {/* Left Side */}
          <div className='p-6 md:p-20'>
            {/* Top Content */}
            <h2 className='font-mono mb-5 text-3xl md:text-4xl font-bold text-left flex'>Log in</h2>
            <p className='max-w-sm mb-12 font-sans font-light text-gray-600 text-left text-xs md:text-base'>Please log in to access your account</p>
            <form onSubmit={onSubmit}>
              <div className='relative mb-2'>
                <input type='text' className='global-input' placeholder='' id='email' name='email' value={email} onChange={onChange} required />
                <label htmlFor='email' className='global-form-label'>
                  Your Email Address
                </label>
              </div>
              <div className='relative mb-2'>
                <input
                  type='password'
                  className='global-input'
                  placeholder=''
                  id='password'
                  name='password'
                  value={password}
                  onChange={onChange}
                  required
                />
                <label htmlFor='password' className='global-form-label'>
                  Your password
                </label>
              </div>
              {/* Middle Content */}
              <div className='flex flex-col space-y-6 md:flex-row justify-between items-center py-6 md:space-y-0 md:space-x-6 text-xs md:text-base'>
                <Link to='/forgot-password' className='font-thin text-cyan-700'>
                  Forgot password?
                </Link>
                <button className='flex md:p-6 p-4  rounded-md btn-primary text-white w-full md:w-auto justify-center items-center space-x-4 font-sans font-bold   shadow-lg px-9  shadow-lime-100 hover:bg-opacity-90 hover:shadow-lg border transition hover:-translate-y-0.5 duration-150'>
                  <span>Submit</span>
                  <img src='images/next-arrow.svg' alt='' />
                </button>
              </div>
            </form>

            {/* Bottom Content */}
          </div>
          {/* Right Side */}
          <img src='images/image.jpg' alt='' className='w-[430px] hidden md:block' />
        </div>
        <input type='checkbox' id='my-modal-6' className='modal-toggle' />
        <div className='modal'>
          <div className='modal-box'>
            <h3 className='font-bold text-lg'>Please register or login now to join this loyalty scheme</h3>
            <p className='py-4'>You'll be able to join the loyalty scheme as soon as you're in</p>
            <div className='modal-action'>
              <button type='button' className='btn' onClick={() => (document.getElementById('my-modal-6').checked = false)}>
                Yay!
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
