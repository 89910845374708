import { useState, useEffect } from 'react'
import BackButton from '../components/BackButton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PrivacyDashboard from '../components/PrivacyDashboard'
import { updateCustomerDetails, getCustomerDetails, reset } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'

const Settings = () => {
  const { user, customerDetails, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [isEditing, setIsEditing] = useState(false)

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: user.email,
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success(customerDetails.message)
    }

    if (isError) {
      toast.error(message)
    }
    dispatch(reset())
  }, [customerDetails, isError, message, isSuccess, dispatch])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)

    const userData = {
      email: formData.email,
    }

    // Add password data only if provided.
    if (formData.currentPassword && formData.newPassword && formData.confirmNewPassword) {
      userData.currentPassword = formData.currentPassword
      userData.newPassword = formData.newPassword
      userData.confirmNewPassword = formData.confirmNewPassword
    }

    dispatch(updateCustomerDetails(userData)).then((res) => {
      if (res.type === updateCustomerDetails.fulfilled.type) {
        setIsEditing(false)

        // Reset the password fields
        setFormData((prevState) => ({
          ...prevState,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }))
      }
    })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onEditButtonClick = () => {
    setIsEditing(true)
  }

  const onCancelButtonClick = () => {
    setIsEditing(false)
    setFormData((prevState) => ({
      ...prevState,
      email: user.email,
    }))
  }

  useEffect(() => {
    dispatch(getCustomerDetails())
  }, [dispatch])

  useEffect(() => {
    if (customerDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: customerDetails.email || prevState.email,
      }))
    }
  }, [customerDetails, isSuccess])

  return (
    <>
      <Helmet>
        <title>Settings | Loyalty Club PLC</title>
      </Helmet>

      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50 shadow-lg'>
        <h1 className='font-bold text-2xl md:text-left'>Profile</h1>
        <div className='divider'></div>

        <form onSubmit={onSubmit}>
          {/* Update email */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CHANGE EMAIL</label>
            <div className='flex w-full  '>
              {isEditing ? (
                <input
                  type='text'
                  className='w-full p-3  border border-gray-300 rounded-md text-xs md:text-base'
                  placeholder='Enter Email Address'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={onChange}
                />
              ) : (
                <span className='mr-3'>{formData.email}</span>
              )}
              {!isEditing && (
                <button type='button' className='btn btn-sm mr-3' onClick={onEditButtonClick}>
                  Edit
                </button>
              )}
              {isEditing && (
                <button type='button' className='btn mr-4 ml-2' onClick={onCancelButtonClick}>
                  Cancel
                </button>
              )}
            </div>
          </div>

          <div className='mt-8 mb-8'>
            {' '}
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>UPDATE PASSWORD</label>
            <p className='mb-3 text-xs '>You can update your password here. If you don't want to change your password, leave these fields empty.</p>
          </div>

          {/* Current Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 '>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CURRENT PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md text-xs md:text-base'
              id='currentPassword'
              name='currentPassword'
              value={formData.currentPassword}
              onChange={onChange}
              placeholder='Enter Current Password'
            />
          </div>

          {/* New Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>NEW PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md text-xs md:text-base'
              id='newPassword'
              name='newPassword'
              value={formData.newPassword}
              onChange={onChange}
              placeholder='Enter New Password'
            />
          </div>

          {/* Confirm New Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CONFIRM NEW PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md md:text-base text-xs'
              id='confirmNewPassword'
              name='confirmNewPassword'
              value={formData.confirmNewPassword}
              onChange={onChange}
              placeholder='Confirm New Password'
            />
          </div>
          {isEditing && emailError && <p className='text-red-500 text-sm'>{emailError}</p>}
          <button className='btn btn-primary btn-sm flex mt-8' disabled={isLoading || emailError}>
            {isLoading ? (
              <>
                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Save Changes</span>
            )}
          </button>

          {isSubmitted && Object.keys(formErrors).length > 0 && (
            <span className='text-red-500 text-sm ml-2 flex mt-2'>Please fill in all required fields</span>
          )}
        </form>
      </div>
      <PrivacyDashboard />
    </>
  )
}

export default Settings
