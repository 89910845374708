import { useSelector } from 'react-redux'
import { BiArrowBack } from 'react-icons/bi'

const MessageSubject = () => {
  const { singleMessage, IndividualMessageIsLoading } = useSelector((state) => state.message)
  const { user } = useSelector((state) => state.auth)

  const replaceTags = (content) => {
    let replacedContent = content
    replacedContent = replacedContent
      .replace(/{name}/g, user.name)
      .replace(/{companyname}/g, singleMessage?.message?.content?.businessName)
      .replace(/{campaignname}/g, singleMessage?.message?.campaignName)
    return replacedContent
  }

  const messageContent = singleMessage.message && singleMessage.message.content && singleMessage.message.content.subject

  const renderedMessageContent = messageContent ? replaceTags(messageContent) : 'Select a message to view'

  return messageContent ? (
    <div className='border h-14 mb-4 rounded-md items-center flex justify-center font-bold bg-white'>
      <div className=' p-12 text-xs md:text-base' style={{ whiteSpace: 'pre-line' }}>
        {IndividualMessageIsLoading ? (
          <div className='flex items-center justify-center text-center text-black text-sm flex-1'>
            <img src='/images/loadingSpinner.svg' alt='Loading' />
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: renderedMessageContent }}
            style={{
              lineHeight: '1.5',
              textIndent: '0px',
            }}
          />
        )}
      </div>
    </div>
  ) : (
    ''
  )
}

export default MessageSubject
