// MapFilter.js
import React from 'react'

const MapFilter = ({ activeCategory, setActiveCategory, categoryIcons }) => {
  const showAllMarkers = () => {
    setActiveCategory(null)
  }

  // Ensure the outermost container has the background color set
  return (
    <div className='flex justify-center bg-white  shadow z-20 rounded-lg'>
      <div className='flex overflow-x-auto no-scrollbar'>
        {/* Inner container with whitespace-nowrap to avoid wrapping */}
        <div className='flex whitespace-nowrap'>
          {/* Button to show all categories */}
          <button
            className={`flex flex-col items-center justify-center text-xs font-medium px-3  
                        ${activeCategory === null ? 'border-b-4 border-black' : 'text-gray-500 hover:bg-blue-50'} 
                        focus:outline-none transition duration-300 ease-in-out`}
            onClick={showAllMarkers}>
            <img
              src='/images/mapIcons/show-all.svg'
              alt='Show All'
              className={`mb-1 w-6 h-6 ${activeCategory === null ? 'text-black' : 'text-gray-500'}`}
            />
            <span>Show All</span>
          </button>
          {/* Map over your category icons */}
          {Object.entries(categoryIcons).map(([category, iconUrl]) => {
            const isActive = activeCategory === category
            return (
              <button
                key={category}
                className={`flex flex-col items-center justify-center text-xs font-medium px-3 py-2 
                            ${isActive ? 'border-b-4 border-black' : 'text-gray-500 hover:bg-blue-50'} 
                            focus:outline-none transition duration-300 ease-in-out`}
                onClick={() => setActiveCategory(category)}>
                <img src={iconUrl} alt={category} className={`mb-1 w-6 h-6 ${isActive ? 'text-black' : 'text-gray-500'}`} />
                <span>{category}</span>
              </button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MapFilter
