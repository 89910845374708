import React, { useState } from 'react'
import { Barcode, BarcodeScanner, BarcodeFormat } from '@capacitor-mlkit/barcode-scanning'
import { useEffect } from 'react'
import { BiScan } from 'react-icons/bi'
import { useLocation } from 'react-router-dom'
import { fetchClientID, reset as authReset, resetClientID } from '../features/auth/authSlice'
import { Helmet } from 'react-helmet-async'
import { reset, setClientId, fetchReferralDetails, confirmClientGPDR, resetCampaigns } from '../features/campaign/campaignSlice'
import { useSelector, useDispatch } from 'react-redux'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'

import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'

const QRreader = (props) => {
  const [isScannerActive, setIsScannerActive] = useState(false)
  const [detectedBarcodes, setDetectedBarcodes] = useState([])
  const [gpdr, setGpdr] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const isMobile = windowWidth <= 640
  const [hasJoinedCampaign, setHasJoinedCampaign] = useState(false)
  const [barcodes, setBarcodes] = useState([])
  const { isSuccess, joinedCampaignIsSuccess, isError, isLoading, userDetails, campaigns, message, clientId, referringCustomerID } = useSelector(
    (state) => state.campaign
  )
  const { clientID } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()

  //resets campaigns when component unmounts
  useEffect(() => {
    return () => {
      BarcodeScanner.removeAllListeners()
      dispatch(resetCampaigns())
      dispatch(resetClientID())
    }
  }, [dispatch])

  //error handling
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (joinedCampaignIsSuccess) {
      toast.success('Successfully joined campaign!')
      dispatch(reset())
    }

    if (isError) {
      toast.error(message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, joinedCampaignIsSuccess])

  const resetClientData = () => {
    console.log('reset customer data runs')
    dispatch(reset())
    dispatch(resetClientID())
    dispatch(setClientId(''))
    dispatch(resetCampaigns())
  }

  function getClientID(url) {
    // Extract the part of the URL after the last '/'
    const code = url.split('/').pop()

    // Check if the extracted part is exactly 6 characters (digits or letters a-f)
    if (code && code.length === 6 && /^[0-9a-fA-F]{6}$/.test(code)) {
      return code
    }
    return null
  }

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions()
    return camera === 'granted' || camera === 'limited'
  }

  const scanQRCode = async () => {
    try {
      const granted = await requestPermissions()
      if (!granted) {
        console.error('Camera permission denied')
        return
      }

      const { barcodes } = await BarcodeScanner.scan()
      setBarcodes(barcodes)

      if (barcodes.length > 0) {
        const qrCodeData = barcodes[0].rawValue
        if (qrCodeData.startsWith('q.loyaltyclubplc')) {
          dispatch(fetchClientID(getClientID(qrCodeData)))
        }
      }
    } catch (error) {
      console.error('Error scanning QR code:', error)
    }
  }

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined && Array.isArray(userDetails.campaignsJoined) && userDetails.campaignsJoined.length > 0) {
      setHasJoinedCampaign(true)
    } else {
      setHasJoinedCampaign(false)
    }
  }, [userDetails])

  useEffect(() => {
    if (userDetails && userDetails.clients) {
      const client = userDetails.clients.find((c) => c.clientID === (clientId || clientID))
      if (client) {
        setGpdr(client.acceptedGPDR)
      }
    }
  }, [userDetails, clientId, clientID])

  useEffect(() => {
    // Check for query parameters from login/register page
    const params = new URLSearchParams(location.search)
    const clientId = params.get('clientId')
    const customerReferral = params.get('referral')

    if (clientId) {
      dispatch(setClientId(clientId))
    }

    if (customerReferral) {
      dispatch(fetchReferralDetails(customerReferral))
    }
  }, [location.search, dispatch])

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   console.log('submit')
  // }

  // const onChange = (e) => {
  //   setGpdr(e.target.checked)

  //   let idToUse
  //   if (clientID && clientID.length === 24) {
  //     idToUse = clientID
  //   } else if (clientId && clientId.length === 24) {
  //     idToUse = clientId
  //   }

  //   if (idToUse) {
  //     console.log(e.target.checked, idToUse)
  //     dispatch(confirmClientGPDR({ gpdr: e.target.checked, idToUse }))
  //   } else {
  //     console.error('No valid idToUse found')
  //   }
  // }
  const filteredCampaigns = campaigns.filter((campaign) => {
    if (referringCustomerID === null) {
      // if referringCustomerID is null, then the user is not a referral, so they can join any campaign with enrolmentViaQr
      return campaign.enrolmentViaQr
    } else if (
      (referringCustomerID && !campaign.enrolmentViaQr && campaign.enrolmentViaIntroduction) || // new condition
      (referringCustomerID && campaign.enrolmentViaQr) // existing condition
    ) {
      return true
    } else {
      return false
    }
  })

  return (
    <div className='flex flex-col justify-center  md:min-h-0  ' style={{ minHeight: 'calc(100vh - 74px - 64px)' }}>
      <Helmet>
        <title>Reader | Loyalty Club PLC</title>
      </Helmet>
      {/* <div className='flex mb-8'>
        <BackButton />
      </div> */}
      {isLoading && <Spinner />}{' '}
      <div className='flex justify-center'>
        <button onClick={scanQRCode} className='group transform transition-transform duration-500 hover:scale-101'>
          <div className='card w-full h-36 bg-base-100 shadow-xl hover:shadow-2xl hover:bg-gray-100'>
            <div className='card-body flex flex-col justify-start items-center'>
              <div>
                <BiScan size={36} className='mx-auto group-hover:text-primary' />
              </div>
              <h2 className='card-title text-sm group-hover:text-primary'>Scan QR Code</h2>
            </div>
          </div>
        </button>
      </div>
      {campaigns.length >= 1 ? (
        <>
          <p className='text-xl mb-3 font-bold'>
            {filteredCampaigns.length} campaigns found for {campaigns[0]?.businessName}{' '}
          </p>
          <button className='btn' onClick={() => (document.getElementById('my-modal-6').checked = true)}>
            View Loyalty Campaigns
          </button>
        </>
      ) : (
        <h1 className='text-lg font-bold mt-8'> Please Scan a Partners QR code</h1>
      )}
      <JoinPartnerCampaign clientId={clientId} clientID={clientID} referringCustomerID={referringCustomerID} resetClientData={resetClientData} />
    </div>
  )
}

export default QRreader
