// Category to icon map for filter buttons
export const categoryIcons = {
  Entertainment: `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/entertainment.svg`,
  'Hair/Beauty': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/scissors.svg`,
  'Health/Fitness': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/health.svg`,
  'High Street Shop': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/shopping-bag.svg`,
  'Places to Stay': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/bed.svg`,
  'Pubs/Bars': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/cocktail.svg`,
  'Restaurants/Cafes': `${process.env.REACT_APP_PUBLIC_URL}/images/mapIcons/knife.svg`,
  // Add other categories as needed
}

const categoryToBusinessTypes = {
  Entertainment: [
    'Adventure Park',
    'Amusement Arcade',
    'Bowling Alley',
    'Cinema',
    'Dance Hall',
    'Go Carts',
    'Paint A Pot',
    'Paint Ball',
    'Playhouse',
    'Pool Hall',
    'Snooker Club',
    'Theatre',
  ],
  'Hair/Beauty': ['Eye Lash/Brow Treatment', 'Hairdresser Mens', 'Hairdresser Unisex', 'Hairdresser Womens', 'Nail Salon', 'Tanning Studio'],
  'Health/Fitness': [
    'Acupuncture Clinic',
    'Boxing Club',
    'Chiropractor',
    'Dance Studio',
    'Fitness Centre/Gym',
    'Martial Arts Center',
    'Massage Therapy Clinic',
    'Physiotherapy Clinic',
    'Pilates Studio',
    'Spa and Wellness Centre',
    'Sports Club',
    'Swimming Pool',
    'Yoga Studio',
  ],
  'High Street Shop': [
    'Art Shop',
    'Bed Store',
    'Bedding',
    'Bike Shop',
    'Book Shop',
    'Cake Decorators',
    'Card Shop',
    'Cobblers',
    'Convenience Store',
    'Craft Shop',
    'Curtains & Fabrics',
    'Discount Store',
    'Dog Grooming',
    'Drapers',
    'Dry Cleaners',
    'Electrical Shop',
    'Ethnic Craft Shop',
    'Florist',
    'Furniture Store',
    'Gift Shop',
    'Jewellers',
    'Kitchen Shop',
    'Leisure & Hobbies',
    'Locksmiths',
    'Music Store',
    'Newsagent',
    'Party Shop',
    'Pet Shop',
    'Phone Shop',
    'Phone Repairs',
    'Pottery Shop',
    'Printers',
    'Record Shop',
    'Soft Furnishings',
    'Sports Shop',
    'Stationery Shop',
    'Taxi Company',
    'Tobacconist',
    'Toy Shop',
    'Travel Shop',
    'TV & Electrical Store',
    'Vape Store',
    'Veterinarian',
  ],
  'Places to Stay': ['Bed and Breakfast', 'Boutique Hotel', 'Camping Site', 'Glamping Site', 'Guest House', 'Hotel'],
  'Pubs/Bars': ['Cocktail Bar', 'Craft Beer Bar', 'Pub', 'Real Ale Bar', 'Vinyard', 'Wine Bar'],
  'Restaurants/Cafes': [
    'American Diner',
    'Caribbean',
    'Chinese',
    'French',
    'Gastro Pub',
    'German',
    'Greek',
    'Ice Cream Parlour',
    'Indian',
    'Italian',
    'Japanese',
    'Mediterranean',
    'Noodles',
    'Polish',
    'Portuguese',
    'Seafood',
    'Spanish',
    'Sushi',
    'Swedish',
    'Tea Shop',
    'Thai',
    'Turkish',
    'Vegan',
    'Vegetarian',
    'Breakfast Van',
  ],
  Takeaways: ['Fried Chicken', 'Ice Cream', 'Indian', 'Kebab', 'Pizza', 'Smoothie/Juice Bar', 'Thai', 'Truck Stop'],
  // Any additional categories go here
}

export const getCategoryFromBusinessType = (businessType) => {
  for (const [category, types] of Object.entries(categoryToBusinessTypes)) {
    if (types.includes(businessType)) {
      return category
    }
  }
  return 'Other' // Default category if none is found
}

const IconMapper = (businessType) => {
  const iconBasePath = '/images/mapIcons/'
  const iconMap = {
    // Entertainment
    'Adventure Park': `${iconBasePath}entertainment.svg`,
    'Amusement Arcade': `${iconBasePath}entertainment.svg`,
    'Bowling Alley': `${iconBasePath}entertainment.svg`,
    Cinema: `${iconBasePath}entertainment.svg`,
    'Dance Hall': `${iconBasePath}entertainment.svg`,
    'Go Carts': `${iconBasePath}entertainment.svg`,
    'Night Club': `${iconBasePath}cocktail.svg`, // Night Club shares icon with Pubs/Bars
    'Paint A Pot': `${iconBasePath}entertainment.svg`,
    'Paint Ball': `${iconBasePath}entertainment.svg`,
    Playhouse: `${iconBasePath}entertainment.svg`,
    'Pool Hall': `${iconBasePath}entertainment.svg`,
    'Snooker Club': `${iconBasePath}entertainment.svg`,
    Theatre: `${iconBasePath}entertainment.svg`,
    // Hair/Beauty
    'Eye Lash/Brow Treatment': `${iconBasePath}scissors.svg`,
    'Hairdresser Mens': `${iconBasePath}scissors.svg`,
    'Hairdresser Unisex': `${iconBasePath}scissors.svg`,
    'Hairdresser Womens': `${iconBasePath}scissors.svg`,
    'Nail Salon': `${iconBasePath}scissors.svg`,
    'Tanning Studio': `${iconBasePath}scissors.svg`,

    // Health/Fitness
    'Acupuncture Clinic': `${iconBasePath}health.svg`,
    'Boxing Club': `${iconBasePath}health.svg`,
    Chiropractor: `${iconBasePath}health.svg`,
    'Dance Studio': `${iconBasePath}health.svg`,
    'Fitness Centre/Gym': `${iconBasePath}health.svg`,
    'Martial Arts Center': `${iconBasePath}health.svg`,
    'Massage Therapy Clinic': `${iconBasePath}health.svg`,
    'Physiotherapy Clinic': `${iconBasePath}health.svg`,
    'Pilates Studio': `${iconBasePath}health.svg`,
    'Spa and Wellness Centre': `${iconBasePath}health.svg`,
    'Sports Club': `${iconBasePath}health.svg`,
    'Swimming Pool': `${iconBasePath}health.svg`,
    'Yoga Studio': `${iconBasePath}health.svg`,

    // High Street Shop (general)
    'Art Shop': `${iconBasePath}shopping-bag.svg`,
    'Bed Store': `${iconBasePath}shopping-bag.svg`,
    Bedding: `${iconBasePath}shopping-bag.svg`,
    'Bike Shop': `${iconBasePath}shopping-bag.svg`,
    'Book Shop': `${iconBasePath}shopping-bag.svg`,
    'Cake Decorators': `${iconBasePath}shopping-bag.svg`,
    'Card Shop': `${iconBasePath}shopping-bag.svg`,
    Cobblers: `${iconBasePath}shopping-bag.svg`,
    'Convenience Store': `${iconBasePath}shopping-bag.svg`,
    'Craft Shop': `${iconBasePath}shopping-bag.svg`,
    'Curtains & Fabrics': `${iconBasePath}shopping-bag.svg`,
    'Discount Store': `${iconBasePath}shopping-bag.svg`,
    'Dog Grooming': `${iconBasePath}shopping-bag.svg`,
    Drapers: `${iconBasePath}shopping-bag.svg`,
    'Dry Cleaners': `${iconBasePath}shopping-bag.svg`,
    'Electrical Shop': `${iconBasePath}shopping-bag.svg`,
    'Ethnic Craft Shop': `${iconBasePath}shopping-bag.svg`,
    Florist: `${iconBasePath}shopping-bag.svg`,
    'Furniture Store': `${iconBasePath}shopping-bag.svg`,
    'Gift Shop': `${iconBasePath}shopping-bag.svg`,
    Jewellers: `${iconBasePath}shopping-bag.svg`,
    'Kitchen Shop': `${iconBasePath}shopping-bag.svg`,
    'Leisure & Hobbies': `${iconBasePath}shopping-bag.svg`,
    Locksmiths: `${iconBasePath}shopping-bag.svg`,
    'Music Store': `${iconBasePath}shopping-bag.svg`,
    Newsagent: `${iconBasePath}shopping-bag.svg`,
    'Party Shop': `${iconBasePath}shopping-bag.svg`,
    'Pet Shop': `${iconBasePath}shopping-bag.svg`,
    'Phone Shop': `${iconBasePath}shopping-bag.svg`,
    'Phone Repairs': `${iconBasePath}shopping-bag.svg`,
    'Pottery Shop': `${iconBasePath}shopping-bag.svg`,
    Printers: `${iconBasePath}shopping-bag.svg`,
    'Record Shop': `${iconBasePath}shopping-bag.svg`,
    'Soft Furnishings': `${iconBasePath}shopping-bag.svg`,
    'Sports Shop': `${iconBasePath}shopping-bag.svg`,
    'Stationery Shop': `${iconBasePath}shopping-bag.svg`,
    'Taxi Company': `${iconBasePath}shopping-bag.svg`,
    Tobacconist: `${iconBasePath}shopping-bag.svg`,
    'Toy Shop': `${iconBasePath}shopping-bag.svg`,
    'Travel Shop': `${iconBasePath}shopping-bag.svg`,
    'TV & Electrical Store': `${iconBasePath}shopping-bag.svg`,
    'Vape Store': `${iconBasePath}shopping-bag.svg`,
    Veterinarian: `${iconBasePath}shopping-bag.svg`,

    // Places to Stay
    'Bed and Breakfast': `${iconBasePath}bed.svg`,
    'Boutique Hotel': `${iconBasePath}bed.svg`,
    'Camping Site': `${iconBasePath}bed.svg`,
    'Glamping Site': `${iconBasePath}bed.svg`,
    'Guest House': `${iconBasePath}bed.svg`,
    Hotel: `${iconBasePath}bed.svg`,

    // Pubs/Bars
    'Cocktail Bar': `${iconBasePath}cocktail.svg`,
    'Craft Beer Bar': `${iconBasePath}cocktail.svg`,
    Pub: `${iconBasePath}cocktail.svg`,
    'Real Ale Bar': `${iconBasePath}cocktail.svg`,
    Vinyard: `${iconBasePath}cocktail.svg`,
    'Wine Bar': `${iconBasePath}cocktail.svg`,
    'American Diner': `${iconBasePath}knife.svg`,

    // Restaurants/Cafes
    Caribbean: `${iconBasePath}knife.svg`,
    Chinese: `${iconBasePath}knife.svg`,
    French: `${iconBasePath}knife.svg`,
    'Gastro Pub': `${iconBasePath}knife.svg`,
    German: `${iconBasePath}knife.svg`,
    Greek: `${iconBasePath}knife.svg`,
    'Ice Cream Parlour': `${iconBasePath}knife.svg`,
    Indian: `${iconBasePath}knife.svg`,
    Italian: `${iconBasePath}knife.svg`,
    Japanese: `${iconBasePath}knife.svg`,
    Mediterranean: `${iconBasePath}knife.svg`,
    Noodles: `${iconBasePath}knife.svg`,
    Polish: `${iconBasePath}knife.svg`,
    Portuguese: `${iconBasePath}knife.svg`,
    Seafood: `${iconBasePath}knife.svg`,
    Spanish: `${iconBasePath}knife.svg`,
    Sushi: `${iconBasePath}knife.svg`,
    Swedish: `${iconBasePath}knife.svg`,
    'Tea Shop': `${iconBasePath}knife.svg`,
    Thai: `${iconBasePath}knife.svg`,
    Turkish: `${iconBasePath}knife.svg`,
    Vegan: `${iconBasePath}knife.svg`,
    Vegetarian: `${iconBasePath}knife.svg`,
    'Breakfast Van': `${iconBasePath}knife.svg`,

    // Takeaways
    'Fried Chicken': `${iconBasePath}knife.svg`,
    'Ice Cream': `${iconBasePath}knife.svg`,
    Indian: `${iconBasePath}knife.svg`,
    Kebab: `${iconBasePath}knife.svg`,
    Pizza: `${iconBasePath}knife.svg`,
    'Smoothie/Juice Bar': `${iconBasePath}knife.svg`,
    Thai: `${iconBasePath}knife.svg`,
    'Truck Stop': `${iconBasePath}knife.svg`,
  }

  return iconMap[businessType] || `${iconBasePath}default-icon.svg`
}

export default IconMapper
