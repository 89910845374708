import axios from 'axios'

const { REACT_APP_SERVER_URL } = process.env

const axiosInstance = axios.create({
  baseURL: REACT_APP_SERVER_URL,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the stored token from client-side storage
      localStorage.clear()
      // Redirect the user to the login page
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
