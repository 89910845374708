import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import { useEffect, useState, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import {
  reset,
  fetchCampaign,
  fetchCustomer,
  fetchCampaignStampCount,
  fetchCampaignStamps,
  fetchRewardImage,
} from '../features/campaign/campaignSlice'
import InfoTooltip from '../components/InfoTooltip'
import StampItem from '../components/StampItem'
import { exchangeStampForTeds, buyStampForTeds, reset as tedsReset, exchangeRewardForTeds, resetTedsValues } from '../features/teds/tedsSlice'
import SkeletonRow from '../components/SkeletonRow'
import { BiArrowBack } from 'react-icons/bi'

const Campaign = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate() // Use the useNavigate hook
  const searchParams = new URLSearchParams(window.location.search)
  const clientID = searchParams.get('clientID')
  const { isSuccess, isError, message, stamps, campaign, userDetails, rewardImageUrl } = useSelector((state) => state.campaign)

  const {
    isSuccess: tedsIsSuccess,
    tedsAwarded,
    message: tedsMessage,
    stampBoughtIsLoading,
    stampSoldIsLoading,
    stampBoughtIsSuccess,
    stampSoldIsSuccess,
    exchangeRewardIsLoading,
    rewardExchangedSuccess,
  } = useSelector((state) => state.teds)

  const { user } = useSelector((state) => state.auth)
  const [myCampaignDetails, setMyCampaignDetails] = useState(null)
  const { campaignID } = useParams()
  const [currentPage, setCurrentPage] = useState(1)
  const rewardValue = campaign?.tedsPerStamp * campaign?.goal
  const buySellSound = useRef(new Audio('/sounds/buy-sell.mp3'))
  const campaignIcons = useRef({})
  let [iconUrl, setIconUrl] = useState('')
  let [rewardUrl, setRewardUrl] = useState('')
  console.log(iconUrl, 'icon url')

  useEffect(() => {
    if (isError) {
      toast.error(message)
    } else {
      dispatch(fetchCampaign(campaignID))
        .then(() => dispatch(fetchCustomer()))
        .then(() => {
          dispatch(fetchCampaignStampCount(campaignID))
        })

      dispatch(fetchCampaignStamps(campaignID))
    }
  }, [dispatch, isError, message, campaignID])

  useEffect(() => {
    if (userDetails && userDetails.campaignsJoined) {
      const campaign = userDetails.campaignsJoined.find((campaign) => campaign.campaignID === campaignID)
      setMyCampaignDetails(campaign)
    }
  }, [userDetails, campaignID])

  const stampsPerPage = 10
  const totalStamps = stamps ? stamps.length : 0
  const lastStampIndex = currentPage * stampsPerPage
  const firstStampIndex = lastStampIndex - stampsPerPage
  const currentStamps = stamps ? stamps.slice(firstStampIndex, lastStampIndex) : []

  const totalPages = Math.ceil(totalStamps / stampsPerPage)
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    if (stampSoldIsSuccess || stampBoughtIsSuccess || rewardExchangedSuccess) {
      buySellSound.current.play()
      // dispatch(resetTedsValues())
    }
  })

  useEffect(() => {
    if (campaign.image) {
      dispatch(fetchRewardImage(campaign.image))
    }

    return () => {
      dispatch(reset())
    }
  }, [dispatch, campaign.image, campaign.icon])

  const handleSellStamp = () => {
    const stampData = {
      campaignId: campaignID,
    }

    dispatch(exchangeStampForTeds(stampData))
      .then(() => {
        // Logic to handle the successful exchange of a stamp for TEDS:
        dispatch(fetchCampaignStampCount(campaignID))
        dispatch(fetchCampaignStamps(campaignID))
      })
      .catch((error) => {
        // Handle errors that might occur during the exchange process
        console.error('Error selling stamp:', error)
        toast.error('Failed to sell stamp. Please try again.') // Example using 'react-toastify'
      })
  }

  const handleBuyStamp = () => {
    const stampData = {
      campaignId: campaignID,
    }

    dispatch(buyStampForTeds(stampData))
      .then(() => {
        // Logic to handle successful stamp purchase:
        dispatch(fetchCampaignStampCount(campaignID))
        dispatch(fetchCampaignStamps(campaignID))
      })
      .catch((error) => {
        // Handle errors that might occur during the purchase process
        console.error('Error buying stamp:', error)
        toast.error('Failed to buy stamp. Please try again.')
      })
  }

  const handleExchangeReward = () => {
    const stampData = {
      campaignId: campaign._id,
    }
    dispatch(exchangeRewardForTeds(stampData)).then(() => {
      dispatch(fetchCampaignStampCount(campaignID))
      dispatch(fetchCampaignStamps(campaignID))
    })
  }

  // back button functionality
  function handleClick() {
    navigate(`/my-campaigns?clientID=${clientID}`)
  }

  useEffect(() => {
    if (campaign.icon) {
      console.log('campaign icon', campaign.icon)
      if (campaign.icon.includes('images/icons/')) {
        setIconUrl(campaign.icon)
        console.log('🚀 ~ useEffect ~ iconUrl:', `${process.env.REACT_APP_PUBLIC_URL}/${campaign.icon}`)
      } else {
        dispatch(fetchRewardImage(campaign.icon)).then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            setIconUrl(result.payload)
            console.log('🚀 ~ dispatch ~ iconUrl:', result.payload)
          }
        })
      }
    }

    if (campaign.image) {
      dispatch(fetchRewardImage(campaign.image)).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setRewardUrl(result.payload)
        }
      })
    }
  }, [campaign.icon, campaign.image, dispatch])

  return (
    <>
      <div>
        <div className='flex mb-8 ml-4'>
          <button className='btn btn-primary btn-square btn-outline' onClick={handleClick}>
            <BiArrowBack size={30} />
          </button>
        </div>
        {/* {isLoading ? <Spinner /> : ''} */}
        {isSuccess && myCampaignDetails ? (
          <>
            {rewardImageUrl && (
              <div className='flex justify-center'>
                <img src={rewardImageUrl} alt='' className='rounded-md mb-5 h-52 w-52' />
              </div>
            )}
            <p className='text-3xl font-bold'>
              <span className=' font-bold'>{campaign.campaignName}</span>{' '}
            </p>
            {campaign.campaignType === 'Spend X Amount To Earn a Stamp' ? (
              <div className='text-center justify-center text-gray-400 text-sm mb-4'>
                Spend {campaign.partnerCurrency}
                {campaign.spendPerStamp} for each stamp earned
              </div>
            ) : (
              <p className='mb-4'> </p>
            )}
            {/* Statistics cards */}
            <div className='grid grid-cols-3 md:grid-cols-3 gap-x-2 md:gap-x-12 mb-8'>
              <div className='card w-full bg-base-100 shadow-xl mr-4 mt-4'>
                <div className='card-body flex-row items-center text-center justify-center'>
                  <div className='flex flex-col items-center relative'>
                    <div className='relative'>
                      <img className='w-10 h-10 m-3' src={iconUrl} alt='' />
                      <span className='absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-primary text-white rounded-full w-12 h-6 flex items-center justify-center text-sm font-bold'>
                        {campaign.newStamps}/{campaign.goal}
                      </span>
                    </div>
                    <h2 className='card-title font-bold flex flex-col text-xs md:text-base'>Current stamp count</h2>
                  </div>
                </div>
              </div>
              <div className='card w-full bg-base-100 shadow-xl mr-4 mt-4'>
                <div className='card-body flex-row items-center text-center justify-center'>
                  <div className='flex flex-col items-center relative'>
                    <div className='relative'>
                      <img className='w-10 h-10 m-3 rounded-md' src={rewardUrl} alt='' />
                      <span className='absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-green-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-sm font-bold'>
                        x{campaign.readyToRedeem}
                      </span>
                    </div>
                    <h2 className='card-title flex flex-col  font-bold text-xs md:text-base'>Rewards Ready to redeem</h2>
                  </div>
                </div>
              </div>

              <div className='card w-full bg-base-100 shadow-xl mr-4 mt-4'>
                <div className='card-body flex-row items-center text-center justify-center'>
                  <div className='flex flex-col items-center relative'>
                    <div className='relative'>
                      <img className='w-10 h-10 m-3 rounded-md' src={rewardUrl} alt='' />
                      <span className='absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-black text-white rounded-full w-6 h-6 flex items-center justify-center text-sm font-bold'>
                        x{campaign.rewardsRedeemed}
                      </span>
                    </div>
                    <h2 className='card-title flex flex-col font-bold text-xs md:text-base'>Total rewards redeemed</h2>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        {/* Button Placement - Before the Table */}
        <div className='flex justify-center mb-4'>
          {campaign?.newStamps < 1 ? (
            <Tippy content='You have no stamps available to sell' placement='top' arrow={true} delay={[200, 0]}>
              <span className='disabled-wrapper'>
                <button className='btn btn-secondary mr-2 btn-disabled' disabled>
                  Sell Stamp
                </button>
              </span>
            </Tippy>
          ) : (
            <button className='btn btn-secondary mr-2' onClick={() => (document.getElementById('sellStampModalToggle').checked = true)}>
              Sell Stamp
            </button>
          )}

          {user?.wallet?.tedsBalance < campaign?.tedsPerStamp ? (
            <Tippy content='You have Insufficient TEDS balance to buy a new stamp' placement='top' arrow={true} delay={[200, 0]}>
              <span className='disabled-wrapper'>
                <button className='btn btn-secondary mr-2 btn-disabled' disabled>
                  Buy Stamp
                </button>
              </span>
            </Tippy>
          ) : (
            <button className='btn btn-secondary mr-2' onClick={() => (document.getElementById('buyStampModalToggle').checked = true)}>
              Buy Stamp
            </button>
          )}
          {campaign?.readyToRedeem < 1 ? (
            <Tippy content='No rewards available to sell' placement='top' arrow={true} delay={[200, 0]}>
              <span className='disabled-wrapper'>
                <button className='btn btn-primary btn-disabled' disabled>
                  Sell Reward
                </button>
              </span>
            </Tippy>
          ) : (
            <button className='btn btn-primary' onClick={() => (document.getElementById('rewardModalToggle').checked = true)}>
              Sell Reward
            </button>
          )}
        </div>
        <div className='overflow-x-auto w-full pb-20'>
          <table className='table w-full text-xs md:text-base table-zebra'>
            <thead>
              <tr>
                <th className='text-xxxs lg:text-base md:text-xs pl-4 px-1'>Date</th>
                <th className='text-xxxs lg:text-base md:text-xs pl-4 px-1'>Server Details</th>
                <th className='text-xxxs lg:text-base md:text-xs pl-4 px-1'>Stamp Status</th>
              </tr>
            </thead>
            <tbody>
              {currentStamps && currentStamps.length > 0 ? (
                currentStamps.map((stamp) => <StampItem iconUrl={iconUrl} key={stamp._id} stamp={stamp} campaign={campaign} />)
              ) : (
                <>
                  {isSuccess ? (
                    <tr>
                      <td colSpan={3}>No stamps found</td>
                    </tr>
                  ) : (
                    Array.from({ length: 6 }).map((_, index) => <SkeletonRow key={index} columns={3} />)
                  )}
                </>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th className='text-xxxs md:text-xs lg:text-base pl-4 px-4'>Date</th>
                <th className='text-xxxs lg:text-base md:text-xs pl-4 px-1'>Server Details</th>
                <th className='text-xxxs lg:text-base md:text-xs pl-4 px-1'>Stamp Status</th>x
              </tr>
            </tfoot>
          </table>
        </div>
        {totalPages > 1 && (
          <div className='btn-group mt-4 pb-20'>
            {pageNumbers.map((pageNumber) => (
              <button
                className={`btn ${currentPage === pageNumber ? 'btn-active' : ''}`}
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}>
                {pageNumber}
              </button>
            ))}
          </div>
        )}
      </div>
      {/* Hidden checkbox to control Buy New Stamp modal visibility */}
      <input type='checkbox' id='buyStampModalToggle' className='modal-toggle' />

      {/* Buy New Stamp Modal structure */}
      <div className='modal' id='buyStampModal'>
        <div className='modal-box'>
          {stampBoughtIsLoading ? (
            <div className='text-center'>
              <div className='flex justify-center my-3'>
                <img src='/images/spinner.png' alt='loading...' className='w-10 h-10 animate-spin' />
              </div>
              <p className='text-black font-bold text-lg my-4'>Purchasing Stamp for {campaign.tedsPerStamp} TEDS, Please wait...</p>
            </div>
          ) : stampBoughtIsSuccess ? (
            <div className='text-center'>
              <div className='flex justify-center my-3'>
                <img src={iconUrl} alt='success' className='w-14 h-14' />
              </div>
              <p className='text-black font-bold text-lg my-4'>
                Congratulations! Your have successfully purchased a new stamp from {campaign.businessName} for {campaign.tedsPerStamp} TEDS.
              </p>
              <p className='text-gray-400 text-sm'>Your new balance is {user?.wallet?.tedsBalance} TEDS.</p>
              <div className='modal-action'>
                <button
                  className='btn'
                  onClick={() => {
                    document.getElementById('buyStampModalToggle').checked = false
                    dispatch(resetTedsValues())
                  }}>
                  OK
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className='flex justify-center my-3'>
                <img src={iconUrl} alt='buy stamp' className='w-16 h-16' />
              </div>
              <h3 className='font-bold text-lg'>Buy New Stamp</h3>
              <h3 className='text-gray-400 text-sm'> Current Balance : {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                You can purchase a stamp for the campaign <span className='font-bold'>{campaign.campaignName}</span> from {campaign.businessName} for
                a total of <span className='font-bold'>{campaign.tedsPerStamp} TEDS</span>. Would you like to proceed?
              </p>
              <div className='modal-action'>
                <label htmlFor='buyStampModalToggle' className='btn'>
                  Cancel
                </label>
                <label htmlFor='buyStampModalToggle' className='btn btn-primary' onClick={handleBuyStamp}>
                  Confirm Purchase
                </label>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Hidden checkbox to control Sell Stamp modal visibility */}
      <input type='checkbox' id='sellStampModalToggle' className='modal-toggle' />

      {/* Sell Stamp Modal structure */}
      <div className='modal' id='sellStampModal'>
        <div className='modal-box'>
          {stampSoldIsLoading ? (
            <div className='text-center'>
              <div className='flex justify-center my-3'>
                <img src='/images/spinner.png' alt='loading...' className='w-10 h-10 animate-spin' />
              </div>
              <p className='text-black font-bold text-lg my-4'>Selling Stamp for {campaign.tedsPerStamp * 0.9} TEDS, Please wait...</p>
            </div>
          ) : stampSoldIsSuccess ? (
            <div className='text-center'>
              <div className='flex justify-center my-3'>
                <img src={iconUrl} alt='success' className='w-14 h-14' />
              </div>
              <p className='text-black font-bold text-lg my-4'>
                Congratulations! Your stamp has been successfully sold for {campaign.tedsPerStamp * 0.9} TEDS.
              </p>
              <p className='text-gray-400 text-sm'>Your new balance is {user?.wallet?.tedsBalance} TEDS.</p>
              <div className='modal-action'>
                <button
                  className='btn'
                  onClick={() => {
                    document.getElementById('sellStampModalToggle').checked = false
                    dispatch(resetTedsValues())
                  }}>
                  OK
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className='flex justify-center my-3'>
                <img src={iconUrl} alt='success' className='w-16 h-16' />
              </div>
              <h3 className='font-bold text-lg'>Sell Stamp</h3>
              <h3 className='text-gray-400 text-sm'> Current Balance : {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                Are you sure you want to sell this stamp from the campaign <span className='font-bold'>{campaign.campaignName}</span> for{' '}
                <span className='font-bold'>{campaign.tedsPerStamp * 0.9}TEDS? </span>
                <InfoTooltip
                  dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the loyalty platform.`}
                  position={'tooltip-left'}
                />{' '}
                <br />
              </p>
              <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4' role='alert'>
                <p className='font-bold'>Attention</p>
                <p>Exchanging this stamp is irreversible and will result in the permanent loss of this stamp. </p>
              </div>
              <div className='modal-action'>
                <label htmlFor='sellStampModalToggle' className='btn'>
                  Cancel
                </label>
                <label htmlFor='sellStampModalToggle' className='btn btn-primary' onClick={handleSellStamp}>
                  Confirm Sale
                </label>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Hidden checkbox to control modal visibility - Reward BUY/SELL  */}
      <input type='checkbox' id='rewardModalToggle' className='modal-toggle' />

      {/* Modal structure triggered by the checkbox Reward BUY/SELL */}
      <div className='modal' id='my_modal_9'>
        <div className='modal-box'>
          {exchangeRewardIsLoading ? (
            <div className='text-center'>
              <div className='flex justify-center'>
                <img src='/images/spinner.png' alt='loading...' className='w-10 h-10 animate-spin' />
              </div>
              <p className='text-black font-bold text-lg my-4'>Exchanging Reward for {tedsAwarded} TEDS, Please wait...</p>
            </div>
          ) : rewardExchangedSuccess ? ( // Check if reward exchange was successful
            <div className='text-center'>
              <div className='flex justify-center'>
                {/* Display a success icon or image if you have one */}

                <img src={rewardUrl} alt='success' className='w-14 h-14' />
              </div>
              <p className='text-black font-bold text-lg my-4'>
                Congratulations! Your reward has been successfully exchanged for {tedsAwarded} TEDS.
              </p>
              <p className='text-black text-md my-4'>Your new balance is {user?.wallet?.tedsBalance}TEDS.</p>
              <div className='modal-action'>
                <label
                  onClick={() => {
                    document.getElementById('rewardModalToggle').checked = false
                    dispatch(resetTedsValues())
                  }}
                  htmlFor='rewardModalToggle'
                  className='btn'>
                  OK
                </label>
              </div>
            </div>
          ) : (
            <>
              <div className='flex justify-center items-center'>
                <img src={rewardUrl} alt='success' className='w-20 h-20' />
              </div>
              <h3 className='font-bold text-lg'>Exchange Reward For TEDS?</h3>
              <h3 className='text-gray-400 text-sm'>TEDS Wallet Balance: {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                Are you sure you want to exchange your <span className='font-bold'>{campaign?.rewardName}</span> from {campaign?.businessName} for{' '}
                <span className='font-bold'>
                  {rewardValue * 0.9} TEDS?
                  <InfoTooltip
                    dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the loyalty platform.`}
                  />
                </span>
              </p>
              <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4' role='alert'>
                <p className='font-bold'>Attention</p>
                <p>Exchanging this reward is irreversible and will result in the permanent loss of the reward. </p>
              </div>
              <p className='text-gray-600 text-xs mb-4'></p>
              <div className='modal-action'>
                <label htmlFor='rewardModalToggle' className='btn'>
                  Cancel
                </label>
                <button className='btn btn-primary' onClick={() => handleExchangeReward()}>
                  Exchange
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Campaign
