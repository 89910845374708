import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet-async'
import { FiMail, FiFacebook, FiSend, FiCopy, FiLinkedin, FiMessageSquare } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { PiTelegramLogo } from 'react-icons/pi'
import { FaWhatsapp } from 'react-icons/fa'
import { fetchCustomer, reset } from '../features/campaign/campaignSlice'
import { getReferFriendMessages } from '../features/message/messageSlice'
import toast from 'react-hot-toast'
import BackButton from '../components/BackButton'
import Spinner from '../components/Spinner'

const ReferFriend = () => {
  const { user } = useSelector((state) => state.auth)
  const { isSuccess, isError, isLoading, message, userDetails } = useSelector((state) => state.campaign)
  const { referFriendMessages } = useSelector((state) => state.message)

  const [copied, setCopied] = useState(false)
  const [referralCode, setReferralCode] = useState(user.referralCode)
  const [selectedBusinessName, setSelectedBusinessName] = useState(
    userDetails?.clients && userDetails.clients.length > 0 ? userDetails.clients[0].businessName : ''
  )
  const [selectedOption, setSelectedOption] = useState(false)

  const referralLink = `https://members.loyaltyclubplc.com/register?referralCode=${referralCode}`

  const dispatch = useDispatch()

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  // Fetch refer-a-friend messages when selectedBusinessName changes
  useEffect(() => {
    if (selectedBusinessName) {
      const selectedClient = userDetails.clients.find((client) => client.businessName === selectedBusinessName)
      if (selectedClient) {
        dispatch(getReferFriendMessages(selectedClient.clientID))
      }
    }
  }, [dispatch, selectedBusinessName, userDetails.clients])

  useEffect(() => {
    dispatch(fetchCustomer())
  }, [dispatch])

  // set timeout for display of copied message
  useEffect(() => {
    let timeoutId
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false)
      }, 1400)
    }
    return () => clearTimeout(timeoutId)
  }, [copied])

  const handleCopyLink = () => {
    if (selectedOption) {
      navigator.clipboard.writeText(referralLink)
      setCopied(true)
    }
  }

  const handleEmailInvite = () => {
    if (selectedOption) {
      const emailBody = encodeURIComponent(
        referFriendMessages.Email.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      const mailtoLink = `mailto:?subject=Join me at ${selectedBusinessName} to earn rewards!&body=${emailBody}`
      window.open(mailtoLink, '_self')
    }
  }

  const handleFacebookInvite = () => {
    if (selectedOption) {
      const encodedLink = encodeURIComponent(referralLink)
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`, '_blank')
    }
  }

  const handleWhatsappInvite = () => {
    if (selectedOption) {
      const whatsappMessage = encodeURIComponent(
        referFriendMessages.WhatsApp.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`)
    }
  }

  const handleTwitterInvite = () => {
    if (selectedOption) {
      const twitterMessage = encodeURIComponent(
        referFriendMessages.Twitter.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://twitter.com/intent/tweet?text=${twitterMessage}`)
    }
  }

  const handleLinkedInInvite = () => {
    if (selectedOption) {
      const linkedInMessage = encodeURIComponent(
        referFriendMessages.LinkedIn.replace('{selectedBusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${linkedInMessage}`)
    }
  }

  const handleTelegramInvite = () => {
    if (selectedOption) {
      const telegramMessage = encodeURIComponent(
        referFriendMessages.Telegram.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://t.me/share/url?url=${telegramMessage}`)
    }
  }

  const handleReferralLinkClick = () => {
    navigator.clipboard.writeText(referralLink)
    setCopied(true)
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Refer a Friend | Loyalty Club PLC</title>
      </Helmet>
      {/* <div className='flex mb-8'>
        <BackButton />
      </div> */}

      <div className='flex flex-col items-center space-y-4'>
        <h1 className='text-3xl font-bold text-gray-700 mb-4'>Refer a Friend</h1>
        <p className='m-6  pb-7 text-xs md:text-base'>
          Get rewarded for referring your friends to a loyalty program! Whenever a friend you refer earns their first stamp, you and your friend will
          each receive a stamp. Don't wait any longer, start earning rewards now!
        </p>

        <select
          className='select select-bordered w-full max-w-xs mt-1'
          defaultValue=''
          onChange={(e) => {
            setReferralCode(e.target.value)
            setSelectedOption(true)
            setSelectedBusinessName(e.target.options[e.target.selectedIndex].text)
          }}>
          <option disabled value=''>
            Please select a merchant to refer
          </option>
          {userDetails?.clients?.map((client) => (
            <option key={client.clientID} value={client.referralCode}>
              {client.businessName}
            </option>
          ))}
        </select>

        <div className='grid grid-cols-4 gap-4 md:flex md:space-x-7'>
          <IconContext.Provider
            value={{
              className: `text-blue-500 cursor-pointer hover:text-blue-700 ${selectedOption ? '' : 'opacity-50 pointer-events-none'}`,
            }}>
            <div onClick={handleEmailInvite}>
              <FiMail size={36} />
            </div>
            <div onClick={handleFacebookInvite}>
              <FiFacebook size={36} />
            </div>
            <div onClick={handleTelegramInvite}>
              <PiTelegramLogo size={36} />
            </div>
            <div onClick={handleTwitterInvite}>
              <FaXTwitter size={36} />
            </div>
            <div onClick={handleLinkedInInvite}>
              <FiLinkedin size={36} />
            </div>
            <div onClick={handleWhatsappInvite}>
              <FaWhatsapp size={36} />
            </div>
            <div onClick={handleCopyLink}>
              <FiCopy size={36} />
            </div>
          </IconContext.Provider>
        </div>

        {selectedOption && (
          <>
            <div className='text-sm text-gray-500'>Share your referral link</div>
            <div className='bg-gray-200 rounded-lg p-2 text-sm text-gray-500 relative cursor-pointer' onClick={handleReferralLinkClick}>
              {referralLink}
              {copied && (
                <div className='bg-green-500 text-white px-2 py-1 rounded absolute bottom-12 left-1/2 transform -translate-x-1/2'>
                  Link copied to clipboard
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ReferFriend
