import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import partnerService from './partnerService' // Assuming you have a similar service setup for partners

const initialState = {
  partners: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  logoImageUrl: null,
  imageIsError: null,
  imageIsStatus: null,
  imageIsSuccess: null,
  imageIsLoading: null,
  socialLinksIsLoading: null,
  imageErrorMessage: null,
  profileDetails: {
    aboutYou: '',
    moreDetails: '',
    priceRange: '',
    hoursAvailable: '',
    selectedHours: {
      Monday: [{ opening: '', closing: '' }],
      Tuesday: [{ opening: '', closing: '' }],
      Wednesday: [{ opening: '', closing: '' }],
      Thursday: [{ opening: '', closing: '' }],
      Friday: [{ opening: '', closing: '' }],
      Saturday: [{ opening: '', closing: '' }],
      Sunday: [{ opening: '', closing: '' }],
    },
  },
  introducedClients: [],
  noPartnersFound: false,
}

// Fetch partners
export const fetchPartnerMarkers = createAsyncThunk('partners/fetchPartnerMarkers', async (mapBounds, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    // Assuming partnerService.fetchPartners is a method to fetch partners data
    return await partnerService.fetchPartnerMarkers(mapBounds, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get Partner Logo
export const fetchLogoImage = createAsyncThunk('partners/fetchLogoImage', async (id, { rejectWithValue }) => {
  try {
    return await partnerService.fetchLogoImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// *fetch Partner Social links (fetches social links for 1 partner only)
export const fetchPartnerSocialLinks = createAsyncThunk('campaign/fetchPartnerSocialLinks', async (clientID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnerService.getPartnerSocialLinks(token, clientID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Partner Social links (fetches social links for 1 partner only)
export const fetchProfileDetails = createAsyncThunk('campaign/fetchProfileDetails', async (clientID, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnerService.fetchProfileDetails(token, clientID)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Get partner image
export const fetchPartnerImage = createAsyncThunk('partner/fetchPartnerImage', async (id, { rejectWithValue }) => {
  try {
    return await partnerService.fetchImage(id)
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

// Submit feedback
export const submitFeedback = createAsyncThunk('partner/submit-feedback', async (feedbackData, thunkAPI) => {
  console.log('🚀 ~ submitFeedback ~ feedbackData:', feedbackData)
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnerService.submitFeedback(feedbackData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// **Get introduced clients
export const getIntroducedClients = createAsyncThunk('auth/getIntroducedClients', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnerService.getIntroducedClients(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const referPartner = createAsyncThunk('partner/referPartner', async (businessInfo, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await partnerService.referPartner(businessInfo, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    resetMapPartner: (state) => {
      state.logoImageUrl = null
      state.socialLinks = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartnerMarkers.pending, (state) => {
        state.isLoading = true
        state.isSuccess = false
        state.noPartnersFound = false // Reset this when starting a new fetch
      })
      .addCase(fetchPartnerMarkers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.noPartnersFound = action.payload.noPartnersFound // Set this based on the API response

        const newPartners = action.payload.partners // The partners are now nested under 'partners' key
        const updatedPartners = {}

        // Indexing existing partners for quick lookup
        state.partners.forEach((partner) => {
          updatedPartners[partner._id] = partner
        })

        // Merging new partners into the existing map
        newPartners.forEach((partner) => {
          updatedPartners[partner._id] = partner // This will add or update the partner
        })

        // Convert the map back to an array for the state
        state.partners = Object.values(updatedPartners)
      })
      .addCase(fetchPartnerMarkers.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.noPartnersFound = false // Reset this on error
      })
    builder
      .addCase(fetchLogoImage.pending, (state) => {
        state.imageIsLoading = true
      })
      .addCase(fetchLogoImage.fulfilled, (state, action) => {
        state.imageIsLoading = false
        state.imageIsSuccess = true
        state.logoImageUrl = action.payload !== undefined ? action.payload : null
      })
      .addCase(fetchLogoImage.rejected, (state, action) => {
        state.imageIsSuccess = false
        state.imageIsError = true
        state.imageErrorMessage = action.payload
        state.imageIsLoading = false
      })
    builder
      .addCase(fetchPartnerSocialLinks.pending, (state) => {
        state.socialLinksIsLoading = true
      })
      .addCase(fetchPartnerSocialLinks.fulfilled, (state, action) => {
        state.socialLinksIsLoading = false
        state.isSuccess = true
        state.socialLinks = action.payload
      })
      .addCase(fetchPartnerSocialLinks.rejected, (state, action) => {
        state.isSuccess = false
        state.socialLinksIsLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(fetchProfileDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.profileDetails = {
          aboutYou: action.payload?.aboutYou || '',
          moreDetails: action.payload?.moreDetails || '',
          priceRange: action.payload?.priceRange || '',
          hoursAvailable: action.payload?.hoursAvailable || '',
          selectedHours: action.payload?.selectedHours || {
            Monday: [{ opening: '', closing: '' }],
            Tuesday: [{ opening: '', closing: '' }],
            Wednesday: [{ opening: '', closing: '' }],
            Thursday: [{ opening: '', closing: '' }],
            Friday: [{ opening: '', closing: '' }],
            Saturday: [{ opening: '', closing: '' }],
            Sunday: [{ opening: '', closing: '' }],
          },
          googleMaps: action.payload?.googleMaps || '',
        }
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(submitFeedback.pending, (state) => {
        state.isLoading = true
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
      })
      .addCase(submitFeedback.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getIntroducedClients.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getIntroducedClients.fulfilled, (state, action) => {
        console.log('action.payload', action.payload)
        state.isLoading = false
        state.isSuccess = true
        state.introducedClients = action.payload.clients
      })
      .addCase(getIntroducedClients.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(referPartner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(referPartner.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.message = action.payload.message
      })
      .addCase(referPartner.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, resetMapPartner } = partnerSlice.actions

export default partnerSlice.reducer
