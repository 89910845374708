import api from '../../api'
const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/customers`
const API_IMAGE_URL = `${REACT_APP_SERVER_URL}/api/images`

// Fetch all partners
const fetchPartnerMarkers = async (mapBounds, token) => {
  try {
    // Destructuring mapBounds to get individual values
    const { north, south, east, west, zoom } = mapBounds

    // Constructing the query string with map bounds
    const queryString = `?north=${north}&south=${south}&east=${east}&west=${west}&zoom=${zoom}`

    // Setting up the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    // Making the GET request with api
    const response = await api.get(`${API_URL}/fetch-partner-markers${queryString}`, config)

    return response.data
  } catch (error) {
    // Propagating the error to be handled by the caller
    throw error
  }
}

const fetchLogoImage = async (id) => {
  try {
    const response = await fetch(`${API_IMAGE_URL}/image/${id}`)
    if (!response.ok) {
      throw new Error(`Server returned a ${response.status} status code`)
    }
    const blob = await response.blob()
    const imageSrc = URL.createObjectURL(blob)
    return imageSrc
  } catch (error) {
    console.error(`An error occurred while fetching the image: ${error}`)
    throw error
  }
}

// *get social links for a single client
const getPartnerSocialLinks = async (token, clientID) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const socialLinks = await api.get(`${API_URL}/get-partner-social/${clientID}`, config)

    return socialLinks.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

// Fetch profile details
const fetchProfileDetails = async (token, clientID) => {
  console.log('clientID', clientID)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/${clientID}/fetch-profile-details`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Submit feedback for review
const submitFeedback = async (feedbackData, token) => {
  console.log('🚀 ~ submitFeedback ~ feedbackData:', feedbackData)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.post(`${API_URL}/submit-feedback`, feedbackData, config)
    return response.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 400) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const getIntroducedClients = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(`${API_URL}/get-referred-partners`, config)
  return response.data
}

const referPartner = async (businessInfo, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.post(`${API_URL}/refer-partner`, businessInfo, config)
  return response.data
}

const partnerService = {
  fetchPartnerMarkers,
  fetchLogoImage,
  getPartnerSocialLinks,
  fetchProfileDetails,
  submitFeedback,
  getIntroducedClients,
  referPartner,
}

export default partnerService
