import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { reset, fetchMyCampaigns, fetchCustomer, fetchStampCount, fetchRewardImage, fetchClientSocialLinks } from '../features/campaign/campaignSlice'
import { fetchLogoImage } from '../features/partner/partnerSlice'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { exchangeStampForTeds, exchangeRewardForTeds, buyStampForTeds, reset as tedsReset, resetTedsValues } from '../features/teds/tedsSlice'
import Spinner from '../components/Spinner'
import { fetchProfileDetails } from '../features/partner/partnerSlice'
import InfoTooltip from '../components/InfoTooltip'
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import BackButton from '../components/BackButton'
import toast from 'react-hot-toast'
import { BiGift } from 'react-icons/bi'
import { FaGifts } from 'react-icons/fa'
import { FaExchangeAlt } from 'react-icons/fa'
import { getRewardsMessage } from '../utils/getRewardMessage'
import { Helmet } from 'react-helmet-async'
import About from '../pages/About'
import CustomerFeedback from '../components/CustomerFeedback'

const Campaigns = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const clientIDFromURL = searchParams.get('clientID')
  const { isSuccess, isError, isLoading, message, myCampaigns, socialLinks, stampCountIsLoading, stampCountIsSuccess, userDetails } = useSelector(
    (state) => state.campaign
  )
  const isDataLoaded = !isLoading

  const { user } = useSelector((state) => state.auth)
  const { profileDetails } = useSelector((state) => state.partner)

  const {
    isSuccess: tedsIsSuccess,
    tedsAwarded,
    message: tedsMessage,
    stampBoughtIsLoading,
    stampSoldIsLoading,
    exchangeRewardIsLoading,
    rewardExchangedSuccess,
    stampBoughtIsSuccess,
    stampSoldIsSuccess,
    isError: tedsIsError,
  } = useSelector((state) => state.teds)

  // create state for ''currentCampaign"
  const [currentClientID, setCurrentClientID] = useState('')
  const campaignImages = useRef({})
  const partnerImages = useRef({})
  const campaignIcons = useRef({})
  const buySellSound = useRef(new Audio('/sounds/buy-sell.mp3'))
  const pressTimer = useRef(null)
  const [selectedStamp, setSelectedStamp] = useState(null)
  const [selectedReward, setSelectedReward] = useState(null)
  const [activeTab, setActiveTab] = useState('campaigns')
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isAboutActive, setIsAboutActive] = useState(false)
  const [debouncedLoading, setDebouncedLoading] = useState(false)
  const debounceTimeout = useRef(null)
  const [partnerImagesLoaded, setPartnerImagesLoaded] = useState(false)
  const [iconsLoaded, setIconsLoaded] = useState({})
  const [imageLoadingStates, setImageLoadingStates] = useState({})
  const googleReviewUrl = profileDetails?.googleMaps?.googleMapsLink
  const requestReviewAfter = profileDetails?.googleMaps?.requestReviewAfter

  // Check if the user has already left a review for this client
  const hasLeftReview = userDetails?.clients?.find((client) => client.clientID === currentClientID)?.leftReview || false

  const numberOfVisits = userDetails?.clients?.find((client) => client.clientID === currentClientID)?.numberOfVisits || 0

  // Determine if we should show the CustomerFeedback component
  const shouldShowFeedback = !hasLeftReview && numberOfVisits >= requestReviewAfter

  const debounceLoading = (loading) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = setTimeout(() => {
      setDebouncedLoading(loading)
      debounceTimeout.current = null
    }, 300) // Adjust the timeout to fine-tune the debouncing effect
  }

  // Sort campaigns to move 'legacy' status to the bottom
  const sortedCampaigns = useMemo(() => {
    return [...myCampaigns].sort((a, b) => {
      if (a.status === 'legacy' && b.status !== 'legacy') return 1
      if (a.status !== 'legacy' && b.status === 'legacy') return -1
      return 0
    })
  }, [myCampaigns])

  //delay loading spinner so it displays consistently
  useEffect(() => {
    debounceLoading(isLoading)
  }, [isLoading])

  // Load partner images on page load
  useEffect(() => {
    const loadImages = async () => {
      const newLoadingStates = {}
      for (const clientID in partnerImages.current) {
        newLoadingStates[clientID] = false
        const img = new Image()
        img.onload = () => {
          setImageLoadingStates((prev) => ({ ...prev, [clientID]: true }))
        }
        img.onerror = () => {
          setImageLoadingStates((prev) => ({ ...prev, [clientID]: true }))
        }
        img.src = partnerImages.current[clientID]
      }
      setImageLoadingStates(newLoadingStates)
    }
    if (partnerImagesLoaded) {
      loadImages()
    } else {
    }
  }, [partnerImagesLoaded, partnerImages])

  // Fetch partner images
  useEffect(() => {
    if (userDetails && userDetails.clients && userDetails.clients.length > 0) {
      fetchPartnerImages()
    }
  }, [userDetails])

  const handleToggle = () => {
    setIsAboutActive(!isAboutActive)
    setActiveTab(isAboutActive ? 'campaigns' : 'about')
  }

  // get unique business names from my campaigns array
  const uniqueBusinessNamesWithClientID = useMemo(() => {
    // Create an array of objects containing businessName and clientID
    const businessNamesAndIDs = myCampaigns.map((campaign) => ({
      businessName: campaign.businessName,
      clientID: campaign.clientID,
    }))

    // Filter out duplicates based on the businessName
    const uniqueBusinessNamesAndIDs = businessNamesAndIDs.filter(
      (item, index, array) => array.findIndex((otherItem) => otherItem.businessName === item.businessName) === index
    )

    return uniqueBusinessNamesAndIDs
  }, [myCampaigns])

  // Reset state when there is a success or error
  useEffect(() => {
    if (stampBoughtIsSuccess || stampSoldIsSuccess) {
      buySellSound.current.play()
      toast.success(tedsMessage)
      dispatch(resetTedsValues())
    }

    if (tedsIsSuccess) {
      toast.success(tedsMessage)
      dispatch(tedsReset())
    }

    if (isError) {
      toast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message, stampCountIsSuccess, tedsIsSuccess, tedsMessage, stampSoldIsSuccess, stampBoughtIsSuccess])

  // Reset state when there is an error
  useEffect(() => {
    if (tedsIsError) {
      if (tedsMessage === 'This campaign is no longer active, you can no longer buy stamps') {
        toast.error(tedsMessage)
      } else {
        document.getElementById('teds_error_modal').checked = true
      }
    }
    dispatch(tedsReset())
  }, [tedsIsError, dispatch, tedsMessage])

  // fetch customers campaigns
  useEffect(() => {
    dispatch(fetchMyCampaigns()).then(() => {
      dispatch(fetchCustomer())
      dispatch(fetchStampCount())
      dispatch(fetchClientSocialLinks())
    })
  }, [dispatch])

  // fetch profile details for about us page
  useEffect(() => {
    if (currentClientID) {
      dispatch(fetchProfileDetails(currentClientID))
    }
  }, [dispatch, currentClientID])

  // set currentClientID to the clientID of the businessName that was clicked
  useEffect(() => {
    if (myCampaigns.length > 0) {
      if (clientIDFromURL) {
        setCurrentClientID(clientIDFromURL)
      } else {
        setCurrentClientID(uniqueBusinessNamesWithClientID[0].clientID)
      }
    }
  }, [myCampaigns, uniqueBusinessNamesWithClientID, clientIDFromURL])

  // Fetch campaign icons
  useEffect(() => {
    myCampaigns.forEach((campaign) => {
      if (campaign.icon) {
        if (campaign.icon.includes('images/icons/')) {
          campaignIcons.current[campaign.icon] = campaign.icon
          setIconsLoaded((prevIconsLoaded) => ({
            ...prevIconsLoaded,
            [campaign.icon]: true,
          }))
        } else if (!campaignIcons.current[campaign.icon]) {
          dispatch(fetchRewardImage(campaign.icon)).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
              campaignIcons.current = {
                ...campaignIcons.current,
                [campaign.icon]: result.payload,
              }
              setIconsLoaded((prevIconsLoaded) => ({
                ...prevIconsLoaded,
                [campaign.icon]: true,
              }))
            }
            setHasLoaded(true)
          })
        }
      }
    })
  }, [myCampaigns, dispatch])

  // fetch campaign images
  useEffect(() => {
    myCampaigns.forEach((campaign) => {
      if (campaign.image && !campaignImages.current[campaign.image]) {
        dispatch(fetchRewardImage(campaign.image)).then((result) => {
          if (result.meta.requestStatus === 'fulfilled') {
            campaignImages.current = {
              ...campaignImages.current,
              [campaign.image]: result.payload,
            }
          }
        })
      }
    })
  }, [myCampaigns, dispatch])

  const fetchPartnerImages = async () => {
    console.log('Starting fetchPartnerImages')
    const promises = userDetails.clients.map(async (client) => {
      if (client.logo && !partnerImages.current[client.clientID]) {
        console.log(`Fetching logo for clientID: ${client.clientID}`)
        const result = await dispatch(fetchLogoImage(client.logo))
        if (result.meta.requestStatus === 'fulfilled') {
          console.log(`Logo fetched successfully for clientID: ${client.clientID}`)
          console.log('Logo Image URL:', result.payload)
          partnerImages.current = {
            ...partnerImages.current,
            [client.clientID]: result.payload,
          }
        } else {
          console.error(`Failed to fetch logo for clientID: ${client.clientID}`)
        }
      }
    })

    await Promise.all(promises)
    console.log('All partner images fetched, setting partnerImagesLoaded to true')
    setPartnerImagesLoaded(true)
  }

  // set currentClientID to the clientID of the businessName that was clicked
  const handleClick = (clientID) => {
    setCurrentClientID(clientID)
  }

  // handles long press when buying/selling a stamp or reward
  const handleLongPressStart = (event, campaign) => {
    event.preventDefault()

    clearTimeout(pressTimer.current)
    pressTimer.current = window.setTimeout(() => {
      setSelectedStamp({
        tedsPerStamp: campaign.tedsPerStamp,
        // Check explicitly for the existence of tedsActive, default to false if not present
        tedsActive: typeof campaign.tedsActive !== 'undefined' ? campaign.tedsActive : false,
        campaignId: campaign._id,
      })
      document.getElementById('modalToggle').checked = true
    }, 800)
  }

  const handleStampClick = (event, campaign) => {
    event.preventDefault()

    clearTimeout(pressTimer.current)
    pressTimer.current = window.setTimeout(() => {
      setSelectedStamp({
        tedsPerStamp: campaign.tedsPerStamp,
        tedsActive: typeof campaign.tedsActive !== 'undefined' ? campaign.tedsActive : false,
        campaignId: campaign._id,
      })
      // Logic to open the modal on click
      document.getElementById('modalToggle').checked = true
    }, 800)
  }

  // clears timeout for long press on buy/sell stamp or rewardd
  const handleMouseUpOrLeave = () => {
    // Clear the timer when the mouse is released or leaves the element
    clearTimeout(pressTimer.current)
  }

  // handles stamp sale
  const handleSellStamp = (selectedStamp) => {
    const stampData = {
      campaignId: selectedStamp.campaignId,
    }

    dispatch(exchangeStampForTeds(stampData))
  }

  // handles reward sale
  const handleExchangeReward = (selectedReward) => {
    const stampData = {
      campaignId: selectedReward.campaignId,
    }
    dispatch(exchangeRewardForTeds(stampData))
  }
  // handles buy new stamp
  const handleBuyNewStamp = (selectedStamp) => {
    const stampData = {
      campaignId: selectedStamp.campaignId,
    }

    dispatch(buyStampForTeds(stampData)).then(() => {
      // Find the relevant campaign again after the update
      const campaign = myCampaigns.find((camp) => camp._id === selectedStamp.campaignId)

      // Check if stamps acquired equal campaign goal
      if (campaign && campaign.newStampCount === campaign.goal - 1) {
        console.log('Reward condition met!')
        dispatch(fetchCustomer())
      } else {
        console.log('Reward condition not met')
      }

      // Now fetch the updated stamp count
      dispatch(fetchStampCount())
    })
  }

  // handles reward sale
  const RewardExchangeStart = (event, campaign) => {
    event.preventDefault()

    clearTimeout(pressTimer.current)
    pressTimer.current = window.setTimeout(() => {
      setSelectedReward({
        tedsPerReward: campaign.tedsPerStamp * campaign.goal,
        campaignId: campaign._id,
        businessName: campaign.businessName,
        rewardName: campaign.rewardName,
        // Add other relevant reward info if needed
      })

      console.log('selected Reward', selectedReward)
      document.getElementById('rewardModalToggle').checked = true
    }, 800)
  }

  const resetTeds = () => {
    dispatch(resetTedsValues())
    document.getElementById('rewardModalToggle').checked = false
  }

  return (
    <>
      <Helmet>
        <title>Campaigns | Loyalty Club PLC</title>
      </Helmet>

      {debouncedLoading ? (
        <Spinner />
      ) : isDataLoaded ? (
        <>
          {myCampaigns.length === 0 && (
            <>
              <div className='flex mb-4'>
                <BackButton />
              </div>
              <div className='bg-gray-200 p-4 rounded-lg'>
                <h2 className='text-2xl font-bold'>Your Loyalty Dashboard is a Blank Canvas!</h2>
                <p className='text-lg'>
                  Ahoy there! It appears your loyalty dashboard is as empty as a pirate ship with no rum. 🏴‍☠️ But worry not, you're just a scan away
                  from a treasure trove of rewards.
                </p>

                <h3 className='text-lg font-bold mt-2'>How to Set Sail on your Loyalty Journey:</h3>
                <ul className='list-disc list-inside ml-4'>
                  <li>Spot one of our partner's nifty QR codes.</li>
                  <li>Take out your trusted mobile device.</li>
                  <li>Scan the QR code like you're hunting for treasure!</li>
                </ul>

                <p className='text-lg mt-2'>
                  And voilà! You're in. Soon, you'll be swimming in a sea of points, discounts, and other valuable doubloons. So, what are you waiting
                  for? Get out there and start scanning, matey! 🦜
                </p>
              </div>
            </>
          )}
          <div className='flex flex-col md:flex-row bg-[#f1f2f5] w-full'>
            {myCampaigns.length >= 1 && (
              <>
                <div className='hidden md:block'>
                  <ul className='flex sticky top-20 menu md:w-56 mr-4 '>
                    {uniqueBusinessNamesWithClientID.map(({ businessName, clientID }) => {
                      // Find the corresponding client object in userDetails.clients array
                      const client = userDetails.clients.find((client) => client.clientID === clientID)

                      // Check if the date is valid
                      const lastVisitDate = new Date(client?.dateOfLastVisit)
                      const isValidDate = lastVisitDate instanceof Date && !isNaN(lastVisitDate)

                      return (
                        <li className='' key={clientID}>
                          <button
                            className={`flex flex-col justify-start items-start text-left w-56 shadow p-2 ${
                              currentClientID === clientID ? 'bg-white opacity-90 text-gray-700' : 'bg-gray-200 text-gray-700 hover:bg-gray-100'
                            }`}
                            onClick={() => handleClick(clientID)}
                            disabled={stampCountIsLoading}>
                            <div className='flex items-center'>
                              <div className='w-10 h-10 mr-2 overflow-hidden'>
                                {partnerImages.current[clientID] ? (
                                  imageLoadingStates[clientID] ? (
                                    <img
                                      src={partnerImages.current[clientID]}
                                      alt={`${businessName} logo`}
                                      className='w-full h-full object-cover shadow-3xl border-white align-center mask mask-squircle'
                                    />
                                  ) : (
                                    <div className='w-full h-full bg-gray-200 animate-pulse rounded-full'></div>
                                  )
                                ) : (
                                  <div className='w-full h-full bg-gray-200 rounded-full flex items-center justify-center text-gray-400'>
                                    {businessName.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <div className='flex flex-col'>
                                <div className='font-bold text-left mb-1'>{businessName}</div>
                                {client && isValidDate && (
                                  <span className='-mt-1 text-xs text-gray-500'>Last Visit: {lastVisitDate.toLocaleDateString()}</span>
                                )}
                              </div>
                            </div>
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className='md:hidden flex justify-center mb-6'>
                  <div className='relative inline-block text-left'>
                    {uniqueBusinessNamesWithClientID.length > 1 ? (
                      <select
                        value={currentClientID}
                        onChange={(e) => handleClick(e.target.value)}
                        disabled={stampCountIsLoading}
                        className='select select-secondary w-full select-lg max-w-xs'>
                        {uniqueBusinessNamesWithClientID.map(({ businessName, clientID }) => (
                          <option key={clientID} value={clientID}>
                            {businessName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className='text-2xl font-bold mt-8'>{uniqueBusinessNamesWithClientID[0].businessName}</div>
                    )}
                  </div>
                </div>
              </>
            )}

            <div className='flex flex-col w-full justify-start'>
              {/* Display partner LOGO*/}
              <div className='flex items-center justify-around'>
                {partnerImagesLoaded && partnerImages.current[currentClientID] && (
                  <>
                    <img
                      src={partnerImages.current[currentClientID]}
                      alt={``}
                      className='w-24 h-24 shadow-3xl m-3 border-white align-center mask mask-squircle'
                    />
                  </>
                )}
                {/* Toggle between About and Campaigns */}
                {myCampaigns.length >= 1 ? (
                  <div className='flex items-center justify-center my-8'>
                    <div className='inline-flex rounded-full bg-gray-300 p-2 drop-shadow-xl'>
                      <button
                        className={`px-6 py-2 rounded-full ${!isAboutActive ? 'bg-orange-500 text-white' : 'bg-gray-300 text-gray-700 '}`}
                        onClick={handleToggle}
                        disabled={!isAboutActive}>
                        Campaigns
                      </button>
                      <button
                        className={`px-6 py-2 rounded-full ${isAboutActive ? 'bg-orange-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                        onClick={handleToggle}
                        disabled={isAboutActive}>
                        About
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>

              {activeTab === 'campaigns' ? (
                <div>
                  {sortedCampaigns
                    .filter((campaign) => currentClientID === '' || campaign.clientID === currentClientID)
                    .map((campaign) => (
                      // Loyalty Card
                      <div key={campaign._id} className='flex flex-col my-8'>
                        <div className='bg-white px-4 rounded-lg shadow-lg pt-8 w-full relative'>
                          <div className='mb-5 flex flex-col items-center'>
                            {/* Status badge with integrated tooltip for legacy campaigns */}
                            <div className='absolute top-2 right-2'>
                              <div className='flex items-center justify-center align-middle'>
                                <div className={`badge ${campaign.status === 'active' ? 'badge-success' : 'badge-warning'} text-white`}>
                                  {campaign.status === 'active' ? (
                                    'Active'
                                  ) : (
                                    <div className='flex items-center'>
                                      <span>Campaign Paused by Vendor</span>
                                      <div
                                        className='campaign-tooltip ml-1'
                                        data-tip='You can still sell your existing stamps, but new stamps cannot be purchased for this campaign.'>
                                        <AiOutlineInfoCircle className='text-white' />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            {campaignImages.current[campaign.image] && (
                              <img
                                src={campaignImages.current[campaign.image]}
                                alt={`${campaign.campaignName}`}
                                className='h-1/5 w-1/4 sm:w-1/5 md:w-1/6 shadow-2xl border-white align-center mask mask-squircle'
                              />
                            )}
                            <div className='flex flex-col justify-center flex-grow'>
                              <div className='font-bold text-3xl text-center my-1'>{campaign.campaignName}</div>
                              <div className='text-center justify-center my-3 text-gray-400 text-sm'>
                                Value per stamp {campaign.tedsPerStamp} TEDS
                              </div>
                            </div>
                          </div>
                          <div className='grid grid-flow-row grid-cols-5 sm:grid-cols-6 md:grid-cols-8 lg:grid-cols-9 font-bold items-center gap-4 justify-items-center'>
                            {[...Array(campaign.goal + 1)].map((_, index) => {
                              // Check if the current stamp is filled
                              const isStampFilled = index < campaign.newStampCount

                              // Determine the next empty stamp spot
                              const isNextEmptyStampSpot = index === campaign.newStampCount

                              // Determine if this is the last filled stamp spot for selling actions
                              const isLastFilledStampSpot = index === campaign.newStampCount - 1

                              // Render filled stamp or the next empty spot with the spinner if loading for buying
                              if (isStampFilled) {
                                if (stampSoldIsLoading && campaign._id === selectedStamp?.campaignId && isLastFilledStampSpot) {
                                  // Replace the last filled stamp with a spinner when selling
                                  return (
                                    <div
                                      key={index}
                                      className='h-20 w-20 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400'>
                                      <div className='flex flex-col justify-center items-center'>
                                        <img className='w-7 h-7' src='/images/loadingSpinner.svg' alt='Loading...' />
                                        <p className='text-xs text-gray-400'>Selling stamp...</p>
                                      </div>
                                    </div>
                                  )
                                }
                                // Regular stamp icon
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md shadow-2xl border-2 border-black my-icon pulse flex items-center justify-center'
                                    {...(campaign.newStampCount > 0 && {
                                      onMouseDown: (e) => handleLongPressStart(e, campaign),
                                      onMouseUp: handleMouseUpOrLeave,
                                      onMouseLeave: handleMouseUpOrLeave,
                                      onClick: (e) => handleStampClick(e, campaign),
                                      onTouchStart: (e) => handleLongPressStart(e, campaign, index),
                                      onTouchEnd: handleMouseUpOrLeave,
                                    })}>
                                    {iconsLoaded[campaign.icon] ? (
                                      <img src={campaignIcons.current[campaign.icon]} alt='Stamp' className='w-full h-full' />
                                    ) : (
                                      <div className='flex items-center justify-center w-full h-full'>
                                        <span className='loading loading-spinner loading-md'></span>
                                      </div>
                                    )}
                                  </div>
                                )
                              } else if (isNextEmptyStampSpot && stampBoughtIsLoading && campaign._id === selectedStamp?.campaignId) {
                                // Show spinner in the next empty stamp spot when buying
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400'>
                                    <div className='flex flex-col justify-center items-center'>
                                      <img className='w-7 h-7' src='/images/loadingSpinner.svg' alt='Loading...' />
                                      <p className='text-xs text-gray-400'>Purchasing stamp...</p>
                                    </div>
                                  </div>
                                )
                              } else if (index === campaign.goal) {
                                // Show "Free" for the last spot
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md border-dotted border-2 border-gray-300 shadow-2xl flex justify-center items-center text-gray-400'
                                    {...(campaign.newStampCount > 0 && {
                                      onMouseDown: (e) => handleLongPressStart(e, campaign),
                                      onMouseUp: handleMouseUpOrLeave,
                                      onMouseLeave: handleMouseUpOrLeave,
                                      onClick: (e) => handleStampClick(e, campaign),
                                      onTouchStart: (e) => handleLongPressStart(e, campaign, index),
                                      onTouchEnd: handleMouseUpOrLeave,
                                    })}>
                                    Free
                                  </div>
                                )
                              } else {
                                // Empty stamp spot
                                return (
                                  <div
                                    key={index}
                                    className='w-12 h-12 lg:h-16 lg:w-16 rounded-md border-gray-300 shadow-2xl border-dotted border-2 flex justify-center items-center text-gray-400'
                                    {...(campaign.newStampCount > 0 && {
                                      onMouseDown: (e) => handleLongPressStart(e, campaign),
                                      onMouseUp: handleMouseUpOrLeave,
                                      onMouseLeave: handleMouseUpOrLeave,
                                      onClick: (e) => handleStampClick(e, campaign),
                                      onTouchStart: (e) => handleLongPressStart(e, campaign, index),
                                      onTouchEnd: handleMouseUpOrLeave,
                                    })}>
                                    {index + 1}
                                  </div>
                                )
                              }
                            })}
                          </div>

                          {hasLoaded && (
                            <div className='flex justify-center mt-8 font-bold'>
                              {getRewardsMessage(campaign.newStampCount, campaign.goal, campaign.rewardName)}
                            </div>
                          )}

                          <div className='flex justify-center mt-3'>
                            <Link
                              to={`/campaign/${campaign._id}?clientID=${currentClientID}`}
                              className='mr-3 w-36 justify-center flex flex-nowrap btn text-xs btn-sm btn-primary mt-4'>
                              View
                            </Link>
                          </div>

                          <div className='flex justify-between pt-5'>
                            {campaign.rewardsRedeemed >= 1 ? (
                              <div className='flex p-3 text-xxs md:text-lg font-bold badge badge-outline badge-primary mb-3'>
                                <BiGift />
                                &nbsp;{' '}
                                {campaign.rewardsRedeemed > 1
                                  ? campaign.rewardsRedeemed + ' Rewards redeemed'
                                  : campaign.rewardsRedeemed + ' Reward redeemed'}
                              </div>
                            ) : null}
                            {campaign.readyToRedeem >= 1 ? (
                              <div
                                className='flex text-xxs md:text-lg font-bold badge p-3 badge-outline badge-secondary mb-3'
                                onMouseDown={(e) => RewardExchangeStart(e, campaign)}
                                onMouseUp={handleMouseUpOrLeave}
                                onMouseLeave={handleMouseUpOrLeave}
                                onDoubleClick={(e) => RewardExchangeStart(e, campaign)}>
                                <FaGifts /> &nbsp;
                                {campaign.readyToRedeem} Reward{campaign.readyToRedeem > 1 ? "'s" : ''} ready to redeem
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className='w-full'>
                  <About profileDetails={profileDetails} clientID={currentClientID} socialLinks={socialLinks} userDetails={userDetails} />
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {/* Hidden checkbox to control modal visibility - STAMP BUY/SELL  */}
      <input type='checkbox' id='modalToggle' className='modal-toggle' />

      {/* Modal structure triggered by the checkbox STAMP BUY/SELL  */}
      <div className='modal' id='my_modal_8'>
        <div className='modal-box'>
          {selectedStamp?.tedsActive ? (
            <>
              <div className='flex justify-center my-3'>
                <FaExchangeAlt size={35} />
              </div>

              <h3 className='font-bold text-xl'>Buy or Sell a Stamp?</h3>
              <h3 className='text-gray-400 text-sm'> Current Balance : {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                Would you like to sell this stamp for
                <span className='font-bold'>
                  {' '}
                  {(selectedStamp.tedsPerStamp * 0.9).toFixed(2)} TEDS?{' '}
                  <InfoTooltip
                    dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the platform.`}
                    position={'tooltip-left'}
                  />{' '}
                </span>
                <br />
                or purchase a new stamp for <span className='font-bold'>{selectedStamp.tedsPerStamp} TEDS?</span>
              </p>
            </>
          ) : (
            <>
              <h3 className='font-bold text-lg'>Stamp Not Available for Trade</h3>
              <p className='py-4'>
                Unfortunately, this stamp is currently not available for trading through the TEDS system. Please check back later or explore other
                stamps that might be available.
              </p>
            </>
          )}
          <div className='modal-action'>
            <label htmlFor='modalToggle' className='btn'>
              Cancel
            </label>
            {selectedStamp?.tedsActive && (
              <>
                <label htmlFor='modalToggle' className='btn btn-primary' onClick={() => handleSellStamp(selectedStamp)}>
                  Sell Stamp
                </label>
                {/* Ensure you have a function to handle the buying of a new stamp, like handleBuyNewStamp */}

                {user?.wallet?.tedsBalance < selectedStamp.tedsPerStamp ? (
                  <Tippy content='You have Insufficient TEDS balance to buy a new stamp' placement='top' arrow={true} delay={[200, 0]}>
                    <span className='disabled-wrapper'>
                      <label htmlFor='modalToggle' className='btn btn-secondary btn-disabled ' disabled>
                        Buy New Stamp
                      </label>
                    </span>
                  </Tippy>
                ) : (
                  <label htmlFor='modalToggle' className='btn btn-secondary' onClick={() => handleBuyNewStamp(selectedStamp)}>
                    Buy New Stamp
                  </label>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Hidden checkbox to control modal visibility - Reward BUY/SELL  */}
      <input type='checkbox' id='rewardModalToggle' className='modal-toggle' />

      {/* Modal structure triggered by the checkbox Reward BUY/SELL */}
      <div className='modal' id='my_modal_9'>
        <div className='modal-box'>
          {exchangeRewardIsLoading ? (
            <div className='text-center'>
              <div className='flex justify-center'>
                <img src='/images/spinner.png' alt='loading...' className='w-10 h-10 animate-spin' />
              </div>
              <p className='text-black font-bold text-lg my-4'>Exchanging Reward for TEDS, Please wait...</p>
            </div>
          ) : rewardExchangedSuccess ? ( // Check if reward exchange was successful
            <div className='text-center'>
              <div className='flex justify-center'>
                {/* Display a success icon or image if you have one */}
                <img src='/images/chinese-coins.svg' alt='success' className='w-20 h-20' />
              </div>
              <p className='text-black font-bold text-lg my-4'>
                Congratulations! Your reward has been successfully exchanged for {tedsAwarded} TEDS.
              </p>
              <p className='text-black text-md my-4'>Your new balance is {user?.wallet?.tedsBalance} TEDS.</p>
              <div className='modal-action'>
                <label onClick={() => resetTeds()} htmlFor='rewardModalToggle' className='btn'>
                  OK
                </label>
              </div>
            </div>
          ) : (
            <>
              <div className='flex justify-center items-center'>
                <img src='/images/reward.webp' alt='success' className='w-20 h-20' />
              </div>
              <h3 className='font-bold text-lg'>Exchange Reward For TEDS?</h3>
              <h3 className='text-gray-400 text-sm'>TEDS Wallet Balance: {user?.wallet?.tedsBalance} TEDS</h3>
              <p className='py-4'>
                Are you sure you want to exchange your <span className='font-bold'>{selectedReward?.rewardName}</span> from{' '}
                {selectedReward?.businessName} for{' '}
                <span className='font-bold'>
                  {selectedReward?.tedsPerReward * 0.9} TEDS?
                  <InfoTooltip
                    dataTip={`We've deducted a 10% service fee from the total to cover transaction costs and fund improvements to the loyalty platform.`}
                  />
                </span>
              </p>
              <div className='bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4' role='alert'>
                <p className='font-bold'>Attention</p>
                <p>Exchanging this reward is irreversible and will result in the permanent loss of the reward. </p>
              </div>
              <p className='text-gray-600 text-xs mb-4'></p>
              <div className='modal-action'>
                <label htmlFor='rewardModalToggle' className='btn'>
                  Cancel
                </label>
                <button className='btn btn-primary' onClick={() => handleExchangeReward(selectedReward)}>
                  Exchange
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {shouldShowFeedback && googleReviewUrl && (
        <div className='pb-40'>
          <CustomerFeedback googleReviewUrl={googleReviewUrl} clientID={currentClientID} requestReviewAfter={requestReviewAfter} />
        </div>
      )}
      {/* TEDS Error Modal */}
      <input type='checkbox' id='teds_error_modal' className='modal-toggle' />
      <div className='modal custom-zindex' role='dialog'>
        <div className='modal-box relative'>
          <label htmlFor='teds_error_modal' className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h2 className='font-bold text-2xl mb-4'>Transaction Unable to Complete</h2>
          <p className='mb-4 text-left'>
            We apologize, but we're unable to complete this transaction at the moment. The loyalty program is experiencing a temporary technical
            issue.
          </p>
          <p className='mb-4 text-left'>Here's what you need to know:</p>
          <ul className='list-disc pl-6 mb-4'>
            <li className='text-left mb-1'>Your stamp is safe and will remain valid.</li>
            <li className='text-left mb-1'>We're working to resolve this issue as quickly as possible.</li>
            <li className='text-left mb-1'>Please try again in 24-48 hours.</li>
          </ul>
          <p className='mb-4 text-left'>If the issue persists, please contact our customer support team.</p>
          <p className='mt-6 font-bold text-left'>
            We appreciate your patience and continued loyalty. Thank you for being a valued member of our program!
          </p>
          <div className='modal-action'>
            <label htmlFor='teds_error_modal' className='modal-backdrop btn btn-primary'>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default Campaigns
