import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaGlobe } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'

const MapSocialLinks = ({ socialLinks: { facebookLink, twitterLink, website, instagramLink, emailAddress, phone, youtubeLink } }) => {
  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`
    }
    return url
  }

  const iconStyles = {
    facebook: { color: '#1877F2' },
    instagram: { color: '#E4405F' },
    twitter: { color: '#1DA1F2' },
    youtube: { color: '#FF0000' },
    email: { color: '#D44638' },
    phone: { color: '#25D366' },
    website: { color: '#34495E' },
  }

  return (
    <div className='flex flex-col space-y-2 mt-5 zindex'>
      <div className='flex space-x-4 justify-center'>
        {facebookLink && (
          <a href={ensureProtocol(facebookLink)} target='_blank' rel='noreferrer' className='flex flex-col items-center text-lg'>
            <FaFacebookF style={iconStyles.facebook} />
          </a>
        )}
        {instagramLink && (
          <a href={ensureProtocol(instagramLink)} target='_blank' rel='noreferrer' className='flex flex-col items-center text-lg'>
            <FaInstagram style={iconStyles.instagram} />
          </a>
        )}
        {twitterLink && (
          <a href={ensureProtocol(twitterLink)} target='_blank' rel='noreferrer' className='flex flex-col items-center text-lg'>
            <FaTwitter style={iconStyles.twitter} />
          </a>
        )}
        {youtubeLink && (
          <a href={ensureProtocol(youtubeLink)} target='_blank' rel='noreferrer' className='flex flex-col items-center text-lg'>
            <FaYoutube style={iconStyles.youtube} />
          </a>
        )}
        {emailAddress && (
          <a href={`mailto:${emailAddress}`} className='flex flex-col items-center text-lg'>
            <AiOutlineMail style={iconStyles.email} />
          </a>
        )}
        {phone && (
          <a href={`tel:${phone}`} className='flex flex-col items-center text-lg'>
            <FiPhone style={iconStyles.phone} />
          </a>
        )}
        {website && (
          <a href={ensureProtocol(website)} target='_blank' rel='noreferrer' className='flex flex-col items-center text-lg'>
            <FaGlobe style={iconStyles.website} />
          </a>
        )}
      </div>
    </div>
  )
}

export default MapSocialLinks
