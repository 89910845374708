import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServerImage, fetchRewardImage } from '../features/campaign/campaignSlice'

const StampItem = ({ stamp, campaign, iconUrl }) => {
  const dispatch = useDispatch()
  const { imageCache } = useSelector((state) => state.campaign)
  const defaultAvatar = '/images/icons/blank-avatar.png'

  useEffect(() => {
    if (stamp.serverAvatar && !imageCache[stamp.serverAvatar]) {
      dispatch(fetchServerImage(stamp.serverAvatar))
    }
  }, [stamp.serverAvatar, dispatch, imageCache])

  return (
    <tr key={stamp._id}>
      <td className='table-optimized'>
        <div className='flex flex-col'>
          <div>{new Date(stamp.createdAt).toLocaleDateString()}</div>
          <div className='badge mt-1 text-xxxs sm:text-xs md:text-base'>
            {new Date(stamp.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
          </div>
        </div>
      </td>
      <td className='table-optimized'>
        <div className='flex items-center md:space-x-3 space-x-1 '>
          <div className='avatar'>
            <div className='mask mask-squircle w-8 h-8 md:w-14 md:h-14'>
              <img
                src={stamp.serverName === 'Stamp Purchase' ? iconUrl : imageCache[stamp.serverAvatar] || defaultAvatar}
                alt=''
                className='rounded-md border-black'
              />
            </div>
          </div>
          <div className='space-y-0 sm:space-y-1'>
            <div className='font-bold  text-xxxs sm:text-xs md:text-base'>{stamp.serverName}</div>
            {stamp.serverName === 'Stamp Purchase' && <div className=' opacity-50 '>Stamp purchased for {campaign.tedsPerStamp} TEDS</div>}
            {stamp.serverName !== 'Stamp Purchase' && <div className='text-xxxs sm:text-xs md:text-base opacity-50'>{stamp.position}</div>}
          </div>
        </div>
      </td>
      <td className='table-optimized'>
        {stamp.status !== 'sold' && (
          <div className='text-xxxs sm:text-xs md:text-base font-bold'>
            {stamp.status === 'new' ? 'Unclaimed' : stamp.status.charAt(0).toUpperCase() + stamp.status.slice(1)}
          </div>
        )}
        {stamp.status === 'claimed' && <div className='text-sm opacity-50'>{new Date(stamp.updatedAt).toLocaleDateString()}</div>}
        {stamp.status === 'sold' && (
          <div className='text-sm text-gray-600 sm:space-y-1 space-y-0'>
            <div className='flex items-center '>
              <span className='font-bold text-xxxs sm:text-xs md:text-base'>Sold on:</span>
              <span className='font-bold text-xxxs sm:text-xs md:text-base'>{new Date(stamp.updatedAt).toLocaleDateString()}</span>
            </div>

            <div className='flex items-center space-x-1 '>
              <span className='opacity-50 text-xxxs sm:text-xs md:text-base'>Stamp Sold for</span>
              <span className='opacity-50 text-xxxs sm:text-xs md:text-base'>{(campaign.tedsPerStamp * 0.9).toFixed(2)} TEDS</span>
            </div>
          </div>
        )}
        {stamp.rewardForFirstPurchase && <div className='badge badge-outline'>Reward For First Purchase</div>}
        {stamp.rewardForReferral && <div className='badge badge-outline'>Reward For Referring a friend</div>}
      </td>
    </tr>
  )
}

export default StampItem
