import { useNavigate } from 'react-router-dom'

import { BiArrowBack } from 'react-icons/bi'

function BackButton() {
  let navigate = useNavigate()

  function handleClick() {
    navigate(-1)
  }

  return (
    <button className='btn btn-primary btn-square btn-outline' onClick={handleClick}>
      <BiArrowBack size={30} />
    </button>
  )
}

export default BackButton
