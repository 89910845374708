import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tedsService from './tedsService'
import { updateWallet } from '../auth/authSlice'
import { decrementNewStampCount, decrementReadyToRedeem, incrementNewStampCount, removeLastStamp } from '../campaign/campaignSlice'

const initialState = {
  tedsTransactions: [],
  tedsAwarded: '',
  tedsSpent: '',
  isError: false,
  isSuccess: false,
  rewardExchangedSuccess: false,
  isLoading: false,
  stampSoldIsLoading: false,
  stampBoughtIsLoading: false,
  message: '',
}

// *Fetch Sales
export const getCustomerTEDSTransactions = createAsyncThunk('teds/fetch-transactions', async (filterData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await tedsService.getCustomerTEDSTransactions(token, filterData)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const fetchTedsWallet = createAsyncThunk('teds/fetchTedsWallet', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await tedsService.fetchTedsWallet(token)
    console.log('🚀 ~ fetchTedsWal ~ response:', response)
    // Here you can directly dispatch another action to update the wallet
    thunkAPI.dispatch(updateWallet(response))
    return response // Return new wallet data
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Buy a stamp with teds
export const exchangeStampForTeds = createAsyncThunk('teds/sell-stamp', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await tedsService.exchangeStampForTeds(token, stampData)
    console.log(response)
    thunkAPI.dispatch(updateWallet(response.wallet))
    thunkAPI.dispatch(decrementNewStampCount(response.campaignId))
    thunkAPI.dispatch(removeLastStamp())
    return response
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Buy a stamp with teds
export const exchangeRewardForTeds = createAsyncThunk('teds/sell-reward', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await tedsService.exchangeRewardForTeds(token, stampData)
    thunkAPI.dispatch(decrementReadyToRedeem(response.campaignId))
    thunkAPI.dispatch(updateWallet(response.wallet))

    return response
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// * Sell a stamp with teds

export const buyStampForTeds = createAsyncThunk('teds/buy-stamp', async (stampData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const response = await tedsService.buyStampForTeds(token, stampData)

    console.log(response, 'response from buyStampForTeds')

    thunkAPI.dispatch(updateWallet(response.wallet))
    thunkAPI.dispatch(incrementNewStampCount(response.campaignId))
    return response
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const tedsSlice = createSlice({
  name: 'teds',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.message = ''
      state.isError = false
    },
    resetTedsValues: (state) => {
      state.tedsAwarded = ''
      state.stampSoldIsSuccess = ''
      state.stampBoughtIsSuccess = ''
      state.rewardExchangedSuccess = ''
      state.tedsSpent = ''
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerTEDSTransactions.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomerTEDSTransactions.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.tedsTransactions = action.payload
    })
    builder.addCase(getCustomerTEDSTransactions.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    // Handle wallet fetching
    builder
      .addCase(fetchTedsWallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTedsWallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(fetchTedsWallet.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(exchangeStampForTeds.pending, (state) => {
        state.stampSoldIsLoading = true
        state.stampSoldIsSuccess = false
      })
      .addCase(exchangeStampForTeds.fulfilled, (state, action) => {
        state.stampSoldIsLoading = false
        state.stampSoldIsSuccess = true
        state.message = action.payload.message
        state.tedsAwarded = action.payload.tedsAwarded
      })
      .addCase(exchangeStampForTeds.rejected, (state, action) => {
        state.stampSoldIsLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(buyStampForTeds.pending, (state) => {
        state.stampBoughtIsLoading = true
        state.stampBoughtIsSuccess = false
      })
      .addCase(buyStampForTeds.fulfilled, (state, action) => {
        state.stampBoughtIsLoading = false
        state.stampBoughtIsSuccess = true
        state.message = action.payload.message
        state.tedsSpent = action.payload.tedsSpent
      })
      .addCase(buyStampForTeds.rejected, (state, action) => {
        state.stampBoughtIsLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder
      .addCase(exchangeRewardForTeds.pending, (state) => {
        state.exchangeRewardIsLoading = true
        state.rewardExchangedSuccess = false
      })
      .addCase(exchangeRewardForTeds.fulfilled, (state, action) => {
        state.exchangeRewardIsLoading = false
        state.rewardExchangedSuccess = true
        state.message = action.payload.message
        state.tedsAwarded = action.payload.tedsAwarded
      })
      .addCase(exchangeRewardForTeds.rejected, (state, action) => {
        state.exchangeRewardIsLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, resetTedsValues } = tedsSlice.actions

export default tedsSlice.reducer
