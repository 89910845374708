import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import BackButton from '../components/BackButton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Spinner from '../components/Spinner'
import { fetchQrCode } from '../features/campaign/campaignSlice'

const MyQRcode = () => {
  const { user } = useSelector((state) => state.auth)
  const { qrCodeUrl, qrCodeIsLoading } = useSelector((state) => state.campaign)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.qr_code) {
      dispatch(fetchQrCode(user.qr_code))
    }
  }, [dispatch, user.qr_code])

  if (qrCodeIsLoading) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>My QR Code | Loyalty Club PLC</title>
      </Helmet>
      <div className=''>
        <div className='flex flex-col items-center pb-20 pt-8'>
          <h2 className='text-2xl font-bold'>Flash Your Personal QR Code Like a VIP Pass🌟</h2>
          <p className='text-base mt-6'>
            Strut into our partner stores and show off your personal QR code like you're on the VIP list. It's your golden ticket to collecting stamps
            and cashing in on some fabulous rewards. 🎫
          </p>

          <div className='ml-10 mr-10 mt-10'>
            <img className='mx-auto  w-full h-full rounded-2xl border-8 border-gray-400 shadow-2xl' src={qrCodeUrl} alt='' />
          </div>
          <a className='w-full md:w-52 px-8 md:px-0' href={qrCodeUrl} download>
            <div className='flex w-full mt-20'>
              <button className='btn btn-primary w-full'>Download QR Code</button>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default MyQRcode
