import React, { useEffect, useRef } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// Import marker icon and shadow
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

// Fix marker icons
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
})

const containerStyle = {
  width: '100%',
  height: '500px', // You can adjust this height as needed
}

const ClientMap = ({ clientID, socialLinks }) => {
  let currentLinks = {}

  if (Array.isArray(socialLinks)) {
    currentLinks = socialLinks.find((links) => links.clientID === clientID) || {}
  }

  console.log(currentLinks, 'current links')

  const location = currentLinks.location
  let position = [0, 0] // Note: Leaflet uses an array [lat, lng] for position

  if (location) {
    position = location.split(',').map((coord) => parseFloat(coord))
  }

  const mapRef = useRef(null)

  useEffect(() => {
    if (mapRef.current && location) {
      mapRef.current.flyTo(position, 16)
    }
  }, [location])

  return (
    <div className='text-center pb-10 pt-8 w-full custom-zindex-less2'>
      {location && (
        <>
          <MapContainer center={position} zoom={16} style={containerStyle} ref={mapRef}>
            <TileLayer
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup className='p-0'>
                <h1 className='font-bold text-lg text-center pb-2'>{currentLinks.businessName}</h1>

                <div className='flex flex-col m-0 justify items-center '>
                  {currentLinks.address.addressLine1 && <span className='m-0'>{currentLinks.address.addressLine1}</span>}
                  {currentLinks.address.addressLine2 && <span className='m-0'>{currentLinks.address.addressLine2}</span>}
                  {currentLinks.address.city && <span className='m-0'>{currentLinks.address.city}</span>}
                  {currentLinks.address.region && <span className='m-0'>{currentLinks.address.region}</span>}
                  {currentLinks.address.postcode && <span className='m-0'>{currentLinks.address.postcode}</span>}
                </div>
              </Popup>
            </Marker>
          </MapContainer>
        </>
      )}
    </div>
  )
}

export default ClientMap
