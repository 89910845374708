import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { setPrivacyPolicyAccepted } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'

function PrivacyPolicy() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [accepted, setAccepted] = useState(false)

  const handleCheckboxChange = () => {
    setAccepted(!accepted)
  }

  const handleAcceptance = () => {
    if (accepted) {
      dispatch(setPrivacyPolicyAccepted())
      // navigate('/')
    } else {
      alert('You need to accept the privacy policy to continue.')
    }
  }

  useEffect(() => {
    if (user.acceptedPrivacyPolicy) {
      navigate('/')
    }
  }, [user, navigate])

  return (
    <div className='max-w-screen-md mx-auto mt-10 px-4'>
      <h1 className='text-2xl font-bold mb-4'>Privacy Policy</h1>

      <p>
        Welcome to Our Loyalty Club. This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you
        can update, manage, export, and delete your information.
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Information We Collect</h2>
      <p>
        When you register with Our Loyalty Club, we collect the following information: your name, email address, and phone number. We also collect
        information about your transactions and interactions with our partner businesses. When partner businesses register with us, we collect
        information like business name, business type, location, contact information, and details about the loyalty campaigns they offer.
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Why We Collect Your Data</h2>
      <p>
        We use your data to provide our services, improve our application, and to communicate with you. We also use the data to personalize your
        experiences and provide recommendations based on your usage of our application.
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Information We Share</h2>
      <p>
        We do not share your personal data with companies, organizations, or individuals outside of Our Loyalty Club except in the following cases: -
        With your consent - For legal reasons
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Security</h2>
      <p>
        We are committed to protecting the privacy and security of your information. We use a variety of security measures to protect your data,
        including encryption and authentication tools.
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Accessing and Updating Your Personal Information</h2>
      <p>
        You have the right to access and update your personal data at any time. You can also request the deletion of your data, subject to applicable
        legal requirements.
      </p>

      <h2 className='text-xl font-bold mb-2 mt-4'>Updates to This Policy</h2>
      <p>
        We may change this Privacy Policy from time to time. If we make significant changes, we will notify you of the changes through the Our Loyalty
        Club application or through others means, such as email. To the extent permitted under applicable law, by using our services after such
        notice, you consent to our updates to this policy. We encourage you to periodically review this Privacy Policy for the latest information on
        our privacy practices. We will also make prior versions of our privacy policies available for review. If you have any questions or concerns
        about this Privacy Policy, please feel free to contact us at any time.
      </p>

      <div className='flex items-center mb-4'>
        <input id='accept' type='checkbox' className='form-checkbox h-5 w-5 text-blue-600 mr-2' checked={accepted} onChange={handleCheckboxChange} />
        <label htmlFor='accept' className='text-sm'>
          I have read and accept the Privacy Policy
        </label>
      </div>

      <button
        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${!accepted && 'opacity-50'}`}
        onClick={handleAcceptance}
        disabled={!accepted}>
        Accept and continue
      </button>
    </div>
  )
}

export default PrivacyPolicy
