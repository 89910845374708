import React, { useEffect, useState, useRef, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { fetchPartnerMarkers, fetchLogoImage, fetchPartnerSocialLinks, resetMapPartner } from '../features/partner/partnerSlice'
import { resetCampaigns } from '../features/campaign/campaignSlice'
import { useSelector, useDispatch } from 'react-redux'
import IconMapper, { categoryIcons, getCategoryFromBusinessType } from '../components/IconMapper'
import MapFilter from '../components/MapFilter'
import { FaSearch } from 'react-icons/fa'
import JoinPartnerCampaign from '../components/JoinPartnerCampaign'
import MapSocialLinks from '../components/MapSocialLinks'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const center = [52.5958, -1.6419] // Set initial map center

const AllPartnersMap = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { partners, noPartnersFound, isLoading, isError, isSuccess, logoImageUrl, socialLinks, imageIsLoading, socialLinksIsLoading } = useSelector(
    (state) => state.partner
  )
  console.log(noPartnersFound, 'noPartners found')

  const [selectedPartner, setSelectedPartner] = useState(null)
  const [containerStyle, setContainerStyle] = useState({
    width: '100%',
    height: '500px',
    zIndex: 1,
  })
  const [activeCategory, setActiveCategory] = useState(null)
  const [showJoinCampaign, setShowJoinCampaign] = useState(false)
  const [userLocation, setUserLocation] = useState(null)
  const [zoomLevel, setZoomLevel] = useState(12)
  const [mapCenter, setMapCenter] = useState(center)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const [showRecommendModal, setShowRecommendModal] = useState(false)

  // Add this new state at the beginning of your component
  const [hasSearched, setHasSearched] = useState(false)

  // // accounting for the notch on IOS
  // const defaultPadding = 0
  // const defaultPaddingMap = 150 // or whatever value you prefer for desktop
  // const topPadding = `max(${defaultPadding}px, calc(var(--safe-area-inset-top, 0px) + ${defaultPadding}px))`
  // const topPaddingMap = `max(${defaultPaddingMap}px, calc(var(--safe-area-inset-top, 0px) + ${defaultPaddingMap}px))`

  useEffect(() => {
    // Function to get computed padding
    const getComputedPadding = () => {
      const element = document.querySelector('.top-padding-element')
      if (element) {
        const computedStyle = window.getComputedStyle(element)
        return computedStyle.marginTop // Note: changed from paddingTop to marginTop
      }
      return null
    }

    // Log the computed padding
    console.log('🚀 ~ AllPartnersMap ~ Computed topPadding:', getComputedPadding())

    // Optional: Set up a resize listener to log changes
    const handleResize = () => {
      console.log('🚀 ~ AllPartnersMap ~ Computed topPadding (after resize):', getComputedPadding())
    }
    window.addEventListener('resize', handleResize)

    // Cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Define this near the top of your component, after your state definitions
  const debouncedFetchPartnerMarkers = useDebounce((bounds) => {
    dispatch(fetchPartnerMarkers(bounds))
  }, 1000)

  const mapRef = useRef(null)
  const timeoutRef = useRef(null)

  function useDebounce(callback, delay) {
    const timeoutRef = useRef(null)

    const debouncedFunction = useCallback(
      (...args) => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
          callback(...args)
        }, delay)
      },
      [callback, delay]
    )

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }, [])

    return debouncedFunction
  }

  const checkForVisibleMarkers = useCallback(() => {
    if (!mapRef.current || !mapRef.current.getBounds || !isSuccess) {
      console.log('Map reference not available or partners not loaded. Exiting early')
      return
    }

    // Clear existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      const bounds = mapRef.current.getBounds()
      const visibleMarkers = partners.filter(
        (partner) =>
          partner.location &&
          partner.location.coordinates &&
          bounds.contains(L.latLng(partner.location.coordinates[1], partner.location.coordinates[0]))
      )

      // Only show or hide the modal if a search has been performed

      if (visibleMarkers.length === 0) {
        console.log('No visible markers in current view after search, showing modal')
        setShowRecommendModal(true)
      } else {
        console.log('Visible markers found, hiding modal')
        setShowRecommendModal(false)
      }
    }, 7000)
  }, [partners, setShowRecommendModal, isSuccess, mapRef, hasSearched])

  // useEffect for checking visible markers after partners are loaded
  useEffect(() => {
    if (isSuccess && !isLoading && mapRef.current) {
      checkForVisibleMarkers()
    }
  }, [isSuccess, isLoading, partners, checkForVisibleMarkers, mapRef])

  const handleSearch = useCallback(async (query) => {
    if (query.length < 3) return
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error fetching search results:', error)
    }
  }, [])

  const debouncedHandleSearch = useDebounce(handleSearch, 1000)

  useEffect(() => {
    if (searchQuery.length >= 3) {
      debouncedHandleSearch(searchQuery)
    } else {
      setSearchResults([])
    }
  }, [searchQuery, debouncedHandleSearch])

  const handleSearchResultSelect = useCallback(
    (result) => {
      const newLocation = [parseFloat(result.lat), parseFloat(result.lon)]
      setSearchResults([])
      setSearchQuery('')
      setHasSearched(true) // Set this to true when a search is performed
      if (mapRef.current) {
        mapRef.current.setView(newLocation, 14)
        console.log('Map view updated, partners will be fetched')
        // Fetch partners for the new location
        const newBounds = {
          north: newLocation[0] + 0.1,
          south: newLocation[0] - 0.1,
          east: newLocation[1] + 0.1,
          west: newLocation[1] - 0.1,
          zoom: 14,
        }
        debouncedFetchPartnerMarkers(newBounds)
      }
    },
    [setSearchResults, setSearchQuery, setHasSearched, mapRef, debouncedFetchPartnerMarkers]
  )

  useEffect(() => {
    return () => {
      dispatch(resetCampaigns())
    }
  }, [dispatch])

  function BoundsHandler({ onBoundsChanged }) {
    const map = useMap()

    useEffect(() => {
      if (!map) return

      const handleMove = () => {
        const bounds = map.getBounds()
        const zoom = map.getZoom()
        onBoundsChanged({
          north: bounds.getNorth(),
          south: bounds.getSouth(),
          east: bounds.getEast(),
          west: bounds.getWest(),
          zoom,
        })
      }

      map.on('moveend', handleMove)
      return () => {
        map.off('moveend', handleMove)
      }
    }, [map, onBoundsChanged])

    return null
  }

  // Modify the useEffect hook for geolocation
  useEffect(() => {
    const handleSuccess = (position) => {
      const newLocation = [position.coords.latitude, position.coords.longitude]
      setUserLocation(newLocation)
      setMapCenter(newLocation) // Update map center
      if (mapRef.current) {
        mapRef.current.setView(newLocation, zoomLevel) // Immediately update map view
      }
    }

    const handleError = (error) => {
      console.error('Error occurred while fetching geolocation: ', error)
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError)
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }, [])

  // Modify handleBoundsChanged to call checkForVisibleMarkers
  const handleBoundsChanged = useCallback(
    (newBounds) => {
      if (newBounds.zoom >= 2) {
        debouncedFetchPartnerMarkers(newBounds)
        checkForVisibleMarkers() // Add this line
      }
    },
    [debouncedFetchPartnerMarkers, checkForVisibleMarkers]
  )

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setContainerStyle({ width: '100%', height: '82%' })
      } else {
        setContainerStyle({ width: '100%', height: '82%' })
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const viewCampaigns = () => {
    if (selectedPartner && selectedPartner.clientID) {
      setShowJoinCampaign(true)
    }
  }

  const navigateToPartner = (lat, lng) => {
    const url = `https://www.openstreetmap.org/directions?engine=osrm_car&route=${userLocation[0]},${userLocation[1]};${lat},${lng}`
    window.open(url, '_blank')
  }

  const handleMarkerClick = (partner) => {
    dispatch(resetMapPartner())
    dispatch(resetCampaigns())
    setSelectedPartner(partner)
    dispatch(fetchPartnerSocialLinks(partner.clientID))
    if (partner.businessLogo) {
      dispatch(fetchLogoImage(partner.businessLogo))
    }

    if (mapRef.current) {
      mapRef.current.setView([partner.location.coordinates[1], partner.location.coordinates[0]], 14)
    }
  }

  const createCustomIcon = (businessType) => {
    const iconUrl = IconMapper(businessType)
    return L.divIcon({
      className: 'custom-icon',
      html: `
        <div style="
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        ">
          <img src="${iconUrl}" alt="${businessType}" style="width: 20px; height: 20px;" />
        </div>
      `,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    })
  }
  return (
    <div className='flex flex-col h-full w-full map-parent -mt-5'>
      <div style={{ marginTop: 0 }} className={`fixed pt-5 top-padding-element left-0 right-0 bg-white shadow-md`}>
        <div className='mx-auto'>
          <div className='relative mx-8 custom-zindex-less3'>
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder='Search for a location...'
              className='w-full p-3 pr-10 mr-4 text-sm bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
            />
            <FaSearch className='absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500' />
          </div>
          {/* search results go here*/}
        </div>

        <MapFilter activeCategory={activeCategory} setActiveCategory={setActiveCategory} categoryIcons={categoryIcons} />
      </div>

      <div className={`flex-grow relative mt-36  top-padding-element custom-zindex-less3 `}>
        <MapContainer
          center={mapCenter}
          style={{ ...containerStyle, width: '100%', zIndex: 0 }}
          zoom={zoomLevel}
          ref={mapRef}
          whenCreated={(map) => {
            mapRef.current = map
          }}>
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <BoundsHandler onBoundsChanged={handleBoundsChanged} />
          {Array.isArray(partners) && partners.length > 0 ? (
            partners.map((partner) => {
              if (!partner || !partner.location || !partner.location.coordinates) {
                return null // Skip invalid partners
              }
              const category = getCategoryFromBusinessType(partner.businessType)
              if (!activeCategory || category === activeCategory) {
                return (
                  <Marker
                    key={partner._id}
                    position={[partner.location.coordinates[1], partner.location.coordinates[0]]}
                    icon={createCustomIcon(partner.businessType)}
                    eventHandlers={{
                      click: () => handleMarkerClick(partner),
                    }}>
                    <Popup>
                      <div className='bg-white px-4 pb-4 rounded-lg max-w-sm'>
                        <div className='avatar flex justify-center'>
                          {imageIsLoading ? (
                            <span className='loading loading-spinner loading-sm'></span>
                          ) : (
                            logoImageUrl && (
                              <div className='w-14 h-14 mask mask-squircle'>
                                <img src={logoImageUrl} alt={partner.businessName} />
                              </div>
                            )
                          )}
                        </div>
                        <h3 className='flex text-lg font-bold text-gray-800 items-center justify-center mt-2'>{partner.businessName}</h3>
                        <div className='flex items-center justify-center -mt-4'>
                          <img className='w-4 h-4' src={IconMapper(partner.businessType)} alt='' />
                          <p className='text-gray-500 text-sm pl-1'>{partner.businessType}</p>
                        </div>
                        <div className='flex flex-col'>
                          <button onClick={viewCampaigns} className='btn btn-primary btn-sm mb-2'>
                            View Loyalty Campaigns
                          </button>
                          <button
                            onClick={() => navigateToPartner(partner.location.coordinates[1], partner.location.coordinates[0])}
                            className='btn btn-secondary btn-sm mb-2'>
                            Navigate
                          </button>
                          {socialLinks && Object.keys(socialLinks).length > 0 && <MapSocialLinks socialLinks={socialLinks} />}
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                )
              }
              return null
            })
          ) : (
            <div>No partners to display</div>
          )}
          {userLocation && (
            <Marker
              position={userLocation}
              icon={L.divIcon({
                className: 'custom-icon',
                html: '<div style="background-color: #4285F4; border: 2px solid white; border-radius: 50%; width: 14px; height: 14px;"></div>',
                iconSize: [14, 14],
              })}
            />
          )}
        </MapContainer>
      </div>

      {showJoinCampaign && selectedPartner && <JoinPartnerCampaign clientId={selectedPartner.clientID} setShowJoinCampaign={setShowJoinCampaign} />}

      {showRecommendModal && (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center custom-zindex'>
          <div className='bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative'>
            <button onClick={() => setShowRecommendModal(false)} className='btn btn-sm btn-circle absolute right-2 top-2'>
              ✕
            </button>
            <h2 className='text-2xl font-bold mb-4 text-center'>No Businesses Found</h2>
            <p className='mb-4 text-center'>
              We couldn't find any businesses in this area. Help us grow our network by referring your favorite establishments
            </p>
            <div className='flex flex-row items-center bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6'>
              <div>
                <img src='images/icons/teds_reward.svg' className='h-24 w-24' alt='' />
              </div>
              <div>
                <p className='font-bold text-2xl mb-1'>Earn a Reward!</p>
                <p>
                  Recommend a business and receive <span className='text-lg font-bold'>250 TEDS coins</span> when they join!
                </p>
              </div>
            </div>
            <div className='flex flex-col space-y-3'>
              <button
                className='w-full px-4 py-2 btn btn-primary'
                onClick={() => {
                  navigate('/refer-a-partner')
                  setShowRecommendModal(false)
                }}>
                Recommend a Partner
              </button>
              <button className='w-full px-4 py-2 btn btn-outline btn-secondary' onClick={() => setShowRecommendModal(false)}>
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      )}
      {searchResults.length > 0 && (
        <div
          className='absolute left-0 right-0 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto custom-zindex top-36' // Add this line
        >
          {searchResults.map((result) => (
            <div
              key={result.place_id}
              onClick={() => handleSearchResultSelect(result)}
              className='p-3 hover:bg-gray-100 cursor-pointer border-b border-gray-100 last:border-b-0'>
              <p className='font-semibold text-sm'>{result.display_name.split(',')[0]}</p>
              <p className='text-xs text-gray-500'>{result.display_name.split(',').slice(1).join(',').trim()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AllPartnersMap
