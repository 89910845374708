import api from '../../api'

const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/teds`

const fetchTedsWallet = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const wallet = await api.get(`${API_URL}/fetch-wallet`, config)
    return wallet.data
  } catch (error) {
    if (error.response) {
      // The server responded with a status code outside the 2xx range
      const errorMessage = error.response.data?.error || 'An error occurred while fetching the wallet'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const getCustomerTEDSTransactions = async (token, { page = 1, limit = 10, searchTerm = '' }) => {
  console.log(searchTerm, 'search term')
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const url = `${API_URL}/get-my-transactions/?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    const transactions = await api.get(url, config)

    return transactions.data
  } catch (error) {
    // check for error response
    if (error.response) {
      // check for status code
      if (error.response.status === 404) {
        // get the error message from response data
        const errorMessage = error.response.data.error
        throw new Error(errorMessage)
      }
    }
    throw error
  }
}

const exchangeStampForTeds = async (token, stampData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/exchange-stamp`, stampData, config)
    return response.data
  } catch (error) {
    // Handle errors as in your other service functions
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorMessage = error.response.data?.error || error.response.data?.message || 'An error occurred during the exchange process'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const exchangeRewardForTeds = async (token, stampData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/exchange-reward`, stampData, config)
    return response.data
  } catch (error) {
    // Handle errors as in your other service functions
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorMessage = error.response.data?.error || error.response.data?.message || 'An error occurred during the exchange process'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const buyStampForTeds = async (token, stampData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(`${API_URL}/buy-stamp`, stampData, config)
    return response.data
  } catch (error) {
    // Handle errors as in your other service functions
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorMessage = error.response.data?.error || error.response.data?.message || 'An error occurred during the exchange process'
      throw new Error(errorMessage)
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error('No response received from the server')
    } else {
      // Something happened in setting up the request that triggered an error
      throw new Error('Error setting up the request')
    }
  }
}

const tedsService = {
  getCustomerTEDSTransactions,
  fetchTedsWallet,
  exchangeStampForTeds,
  exchangeRewardForTeds,
  buyStampForTeds,
}

export default tedsService
