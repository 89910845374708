import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import campaignReducer from '../features/campaign/campaignSlice'
import messageReducer from '../features/message/messageSlice'
import partnerReducer from '../features/partner/partnerSlice'
import tedsReducer from '../features/teds/tedsSlice'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    campaign: campaignReducer,
    message: messageReducer,
    partner: partnerReducer,
    teds: tedsReducer,
  },
})
