import React from 'react'

const Modal = ({ id, children, onClose }) => {
  return (
    <>
      <input type='checkbox' id={id} className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle custom-zindex'>
        <div className='modal-box relative'>
          <label htmlFor={id} className='btn btn-sm btn-circle absolute right-2 top-2'>
            ✕
          </label>
          {children}
          <div className='modal-action'>
            <label htmlFor={id} className='btn' onClick={onClose}>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
